import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { KcgService } from 'src/app/_services/kcg.service';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { SharedService } from 'src/app/_services/shared.service';
import { Router } from '@angular/router';
import { AlertService } from '../../alert/alert.service';
import { PasswordService } from '../password.service';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
  selector: 'app-password-update',
  templateUrl: './password-update.component.html',
  styleUrls: ['./password-update.component.scss']
})
export class PasswordUpdateComponent implements OnInit {
  title = 'Change Password';
  @ViewChild('passwordTemplate', { static: true }) private passwordTemplate!: TemplateRef<any>;
  passwordModalReference!: NgbModalRef;
  passwordForm!: FormGroup;
  loading: boolean = false;

  //showChangePassword = true;
  isPasswordExpired: boolean = false;
  passwordError!: any;
  isLoggedIn!: boolean;

  constructor(
    private modalService: NgbModal,
    private kcgService: KcgService,
    private authService: AuthenticationService,
    private sharedService: SharedService,
    private formBuilder: FormBuilder,
    private router: Router,
    private alertService: AlertService,
    private passwordService: PasswordService,
    private oauthService: OAuthService
  ) { }

  ngOnInit(): void {
    this.getLoggedStatus();
    // (async () => {
    //   await this.checkPasswordExpired();
    // })();
    this.listenChangePasswordReq();
  }

  /**
  * getLoggedStatus
  * 
  */
  async getLoggedStatus() {
    const isValid = this.oauthService.hasValidIdToken() && this.oauthService.hasValidAccessToken();
    this.sharedService.newUserLoggedModel$.subscribe(async (userlogged) => {
      this.isLoggedIn = userlogged ? true : false;
     if(this.isLoggedIn) await this.checkPasswordExpired();
    });
    this.isLoggedIn = isValid;
    if(this.isLoggedIn) await this.checkPasswordExpired();
  }



  listenChangePasswordReq() {
    this.passwordService.passwordSubject$
      .subscribe(async (res: any) => {
        //console.log('res', res);
        if (res.show == true) {
          await this.showChangePasswordBlock();
        }
      })
  }

  async checkPasswordExpired() {
    this.isPasswordExpired = await this.getPasswordExpiredStatus();
    if (this.isPasswordExpired) {
      await this.showChangePasswordBlock();
    }
  }

  async showChangePasswordBlock() {
    const kcgUserBasic: { subjectId: string } = await this.authService.getKcgUserBasic();
    this.passwordFormInit(kcgUserBasic.subjectId);
    this.openPasswordModal(this.passwordTemplate);
  }

  // public openChangePassword() {
  //   this.showChangePassword = true;
  // }

  passwordFormInit(subjectId: string) {
    this.passwordForm = this.formBuilder.group({
      subjectId: [subjectId],
      password: ['', [Validators.required]],
      newPassword: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]]
    }, {
      validator: this.passwordMisMatchValidation
    });
  }

  passwordMisMatchValidation(c: AbstractControl): { invalid: boolean } | void {
    if (c.get('newPassword')!.value !== c.get('confirmPassword')!.value) {
      c.get('confirmPassword')!.setErrors({ 'misMatch': true });
      return { invalid: true };
    }
    c.get('confirmPassword')!.setErrors(null);
  }


  onPasswordSubmit(submitForm: any, formDir: any) {
    this.loading = true;
    if (submitForm.status === 'VALID') {
      this.loading = false;
      const reqArg = {
        newPassword: submitForm.value.newPassword,
        oldPassword: submitForm.value.password,
        subjectId: submitForm.value.subjectId,
      };
      this.kcgService.changePassword(reqArg).subscribe(res => {
        this.loading = false;
        if (res.isError) {
          this.passwordError = res.errors;
          return;
        }
        this.closePasswordModal();
        if (this.isPasswordExpired) window.location.reload();
        this.alertService.showSnackBar({
          message: 'Password changed successfully.',
          time: 4000,
          status: "success"
        });

      }, () => {
        this.loading = false;
      });
    }
  }

  async getPasswordExpiredStatus() {
    try {
      const res = await this.kcgService.isPasswordExpired().toPromise();
      return res.data;
    } catch (ex) {
      return false;
    }
  }

  public openPasswordModal(modalTemplate: any) {
    if (this.passwordModalReference) this.passwordModalReference.close();
    this.passwordModalReference = this.modalService.open(modalTemplate, { centered: true, size: 'sm', backdrop: 'static' });
    this.passwordModalReference.result.then(() => {
      this.closePasswordModal();
    }, () => {
      this.closePasswordModal();
    });
  }

  public closePasswordModal() {
    this.passwordForm.reset();
    this.passwordError = null;    
    this.passwordModalReference.close();
  }


  public logOut() {
    this.closePasswordModal();
    this.sharedService.showLoader('Logout', true);
    setTimeout(() => this.sharedService.onUserLogged(false), 20)
    this.authService.logOut();
  }
}
