import { Component, NgModuleRef, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, Form } from '@angular/forms';
import { ClaimsService } from 'src/app/_services/claims.service';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CustomDateFormatService } from 'src/app/_services/custom-date-format.service';
import { KButtonType, KKcgButtonSubType } from 'ng-k-button';
import { NotificationService } from 'src/app/_services/notification.service';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/_services/shared.service';
import { Role } from '../../_models/role';
import { AccessModuleComponents, AccessModules, AccessModuleT } from 'src/app/_models/access-privilege.model';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { AlertService } from 'src/app/_shared/alert/alert.service';
import { UserService } from 'src/app/_services/user.service';
import { DatePipe } from '@angular/common';

interface Users {
  id: number,
  name: string,
  identifier?: string
}
interface ClaimInfo {
  claimInfo: {
    defendantName: string,
    defendantEmail: string,
    defendantPhone: string,
    referanceNumber: string,
    claimDate: string,
    claimantSolicitor: string,
    claimantName: string,
    causeCode?: string,
  }
}

interface Claim {
  documentId: string,
  card_data?: ClaimInfo,
  claimType: string,
  assignedTo: string,
  isActionAllowed: boolean,
  createdAt: string,
  status: number
}

interface ClaimForm {
  title: string,
  identifier: string,
  isMandatory: string,
  fieldType: string
}
interface ClaimType {
  typeofLoss: string,
  title: string,
  formData: ClaimForm[],
  isSelected: boolean
}
interface OrgClaimForm {
  title: string,
  identifier: string,
  isMandatory: string,
  fieldType: string,
  userResponse: string
}
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  // Form
  public searchClaimsFilterForm: any;
  public allClaims: Array<Claim> = [];
  tempClaims = [];
  public allClaimsCount = 0;
  public totalClaimsCount = 0;

  // Pagination
  public page = 1;
  public limit = 20;

  public throttle = 50;
  public scrollDistance = 3;
  public scrollUpDistance = 1.5;

  // Filter
  public filter: { searchKeyword?: string, myClaims?: boolean, orgId?: string } = {};
  public organisationList: any;

  public KButtonType = KButtonType;
  public KKcgButtonSubType = KKcgButtonSubType;
  public newClaimTempRef!: NgbModalRef;
  public delClaimTemplateRef!: NgbModalRef;
  public assignClaimTemplateRef!: NgbModalRef;
  public sendClaimFactsTemplateRef!: NgbModalRef;


  private subscriptions: Subscription[] = [];

  @ViewChild('newClaimTemplate') private newClaimTemplate!: TemplateRef<any>;
  @ViewChild('deleteClaimTemplate') private deleteClaimTemplate!: TemplateRef<any>;
  @ViewChild('assignClaimTemplate') private assignClaimTemplate!: TemplateRef<any>;
  @ViewChild('sendClaimFactsTemplate') private sendClaimFactsTemplate!: TemplateRef<any>;
  // @ViewChild('newClaimTemplate', { read: ViewContainerRef }) container?: ViewContainerRef;

  public newClaimForm: any;

  public initLoad: boolean = true;

  //accessibleRoles = [Role.Admin, Role.SuperAdmin];
  accessModuleClaimCards: AccessModuleT = { moduleKey: AccessModules.claimCards, moduleComponent: AccessModuleComponents.read };
  accessModuleWrite: AccessModuleT = { moduleKey: AccessModules.claim, moduleComponent: AccessModuleComponents.write };
  accessModuleDelete: AccessModuleT = { moduleKey: AccessModules.claim, moduleComponent: AccessModuleComponents.delete };
  accessModuleAssign: AccessModuleT = { moduleKey: AccessModules.claim, moduleComponent: AccessModuleComponents.assign };
  accessModuleClaimSummary: AccessModuleT = { moduleKey: AccessModules.claimSummary, moduleComponent: AccessModuleComponents.read };
  accessModuleOrgFilter: AccessModuleT = { moduleKey: AccessModules.orgFilter, moduleComponent: AccessModuleComponents.write };
  claimCardsAccess = false;

  assignUsers!: any//Users[];
  assignUserForm: FormGroup = new FormGroup({});
  sendClaimFactsForm: FormGroup = new FormGroup({});

  loading: boolean = false;

  claimTypeArr!: ClaimType[];
  claimFormArr!: ClaimForm[];
  orgClaimFormArr: OrgClaimForm[] = [];
  onEditSelectClaim?: Claim | null;

  // claimFormData: { id: string, title: string, type: string, validation: string[] }[] = [];

  constructor(
    private fb: FormBuilder,
    private claimsService: ClaimsService,
    private router: Router,
    private modalService: NgbModal,
    public customDateFormatService: CustomDateFormatService,
    public notificationService: NotificationService,
    public sharedService: SharedService,
    private authService: AuthenticationService,
    private alertService: AlertService,
    private userService: UserService
  ) { }

  async ngOnInit() {
    this.claimCardsAccess = await this.authService.checkAccessPrivilege(this.accessModuleClaimCards);
    if (this.claimCardsAccess) {
      this.getOrganisationList();
      this.searchClaimsFilterFormValidation();
      this.getClaims(this.page, this.limit, null);
    }
  }

  async getOrganisationList() {
    const thisObj = this;
    try {
      const response = await this.claimsService.getAllOrganizations({}).toPromise();
      thisObj.organisationList = response.data;
      return thisObj.organisationList;
    } catch (error) {
      console.log("error", error)
    }
  }

  searchClaimsFilterFormValidation() {
    this.searchClaimsFilterForm = this.fb.group({
      keyword: '',
      claimCategory: 'all',
      orgCategory: ''
    });
  }

  submit_searchClaimsFilterForm(form: any) {
    {
      this.allClaims = [];
      const thisObj = this;
      if (form.status === 'VALID') {
        this.filter = {
          searchKeyword: form.value.keyword,
          myClaims: form.value.claimCategory == 'my' ? true : false,
          orgId: form.value.orgCategory
        }
        this.page = 1;
        this.getClaims(this.page, this.limit, thisObj.filter, true);
      }
    }
  }

  resetSearchClaimForm(form?: any) {
    this.searchClaimsFilterForm.setValue({
      keyword: '',
      claimCategory: 'all',
      orgCategory: ''
    });
    this.page = 1;
    this.filter = {
      searchKeyword: '',
      myClaims: false,
      orgId: ''
    };
    this.getClaims(this.page, this.limit, null, true);
  }


  getClaims(page: any, limit: any, filter?: any, isDelete?: boolean) {
    this.sharedService.showLoader('getClaims', true);
    const requestParam: any = {};
    // Pagination
    requestParam.offset = ((page * limit) - limit);
    requestParam.limit = limit;
    // Pagination
    // Filter
    if (filter) {
      requestParam.keyword = filter.searchKeyword ? filter.searchKeyword : '';
    }
    if (filter?.myClaims == true) {
      requestParam.myClaims = filter.myClaims ? filter.myClaims : false;
    }
    if (filter?.orgId) {
      requestParam.orgId = filter.orgId ? filter.orgId : '';
    }
    // Filter


    const claims = this.claimsService.getClaims(requestParam).subscribe(result => {
      //console.log('Inside getClaims of dashboard component');
      if (result.status === 'Success') {
        //console.log('getClaims success');
        this.totalClaimsCount = result.data.total_claims;
        this.allClaimsCount = result.data.count;
        this.tempClaims = result.data.rows;
        for (const data of result.data.rows) {
          if (data['card_data']) {
            const tempData = data.card_data //JSON.parse(data.card_data);
            data['card_data'] = tempData;
          }
          //(data['card_data']);
        }

        if (isDelete) {
          this.allClaims = result.data.rows;
        } else {
          Array.prototype.push.apply(this.allClaims, result.data.rows);
        }
        //console.log(this.allClaims);
        this.scrollInfiniteFix(this.allClaimsCount, this.allClaims.length);
      }
      this.subscriptions.push(claims);
      this.sharedService.showLoader('getClaims', false);
      this.sharedService.showLoader('loadClaimData', false);
    },
      err => {
        console.log('ERROR');
        this.sharedService.showLoader('getClaims', false);
        this.sharedService.showLoader('loadClaimData', false);
      });
  }

  /**
   *  scroll event dor all documents section
   */
  onScroll() {
    let offset = (this.page * this.limit) - this.limit
    if(offset < this.totalClaimsCount){
      this.loadMore();
    }
  }

  loadMore() {
    this.page++;
    this.getClaims(this.page, this.limit, this.filter);
  }

  public scrollInfiniteFix(fullCount: number, loadedCount: number) {
    //console.log(fullCount + ' and ' + loadedCount);
    if (loadedCount < fullCount) {
      //console.log(loadedCount + '< ' + fullCount);
      document.documentElement.style.height = '101%';
    } else {
      //console.log('else');
      this.scrollInfiniteFixReset();
    }
  }

  /**
   *  scrollInfiniteFixReset
   */
  public scrollInfiniteFixReset() {

    document.documentElement.style.height = 'null';
  }

  viewClaim(claimId: any) {
    //this.router.navigate(['/summaryView/' + claimId]);
    this.router.navigate([`/claim/${claimId}`]);

  }

  /**
   * Nav to claim wizard page
   * @param docId document Id
   */
  viewClaimWizard(docId: any) {
    //this.router.navigate(['/claims/manage/' + docId]);
    this.router.navigate([`/claim/${docId}/facts`]);
    // this.router.navigate(['/claims/manage/61e7bee8762016b59f1dcb61']);
  }

  //  getUsers():Users[]{
  //   return [{id:1, name: 'Hello' },{id:2, name: 'Hey' },{id:3, name: 'Bye' }]
  // }

  async getUsers(claimId: string) {
    try {
      const usersList = await this.userService.getAllKcgUsers({
        liabilityId: claimId
      }).toPromise();
      return usersList.data;
    } catch (err) {
      return null;
    }
  }

  async getActiveLiabilityUser(claimId: string) {
    try {
      const userRes = await this.claimsService.getActiveLiabilityUser({
        liabilityId: claimId
      }).toPromise();
      return userRes.data.active_user;
    } catch (err) {
      return null;
    }
  }

  async openAssignClaim(claim: { documentId: string }) {
    this.sharedService.showLoader('openAssign', true);
    //return false;
    // let args = {
    //   id: claim.documentId
    // }
    const claimId = claim.documentId;
    this.assignUsers = await this.getUsers(claimId);
    const assignedUser = await this.getActiveLiabilityUser(claimId)
    this.sharedService.showLoader('openAssign', false);

    if (!this.assignUsers) return;
    this.initAssignUserForm(this.assignUsers, claim, claimId, assignedUser);
    this.assignClaimTemplateRef = this.modalService.open(this.assignClaimTemplate,
      { centered: true, size: 'sm', backdrop: 'static', keyboard: false }
    );
  }

  initAssignUserForm(users: Users[], claim: object, claimId: string, assignedUser: number) {
    // let userFormControls: any = {};
    // for (let user of users) {
    //   // let userIdentifier = `user-${user.id}`;
    //   // user['identifier'] = userIdentifier;
    //   userFormControls[user.id] = new FormControl(false);
    // }
    // console.log('=======', assignedUser);
    const selectedUser: any = users.find((i: any) => i.kcguserId == assignedUser);
    const selcUserId = selectedUser?.kcguserId ? selectedUser?.kcguserId : '';

    this.assignUserForm = new FormGroup({
      //userGroup: new FormGroup(userFormControls),
      claim: new FormControl(claim),
      claimId: new FormControl(claimId),
      user: new FormControl(selcUserId, [Validators.required])
    })
  }

  /**
   * onSubmitAssign
   */
  onSubmitAssign() {
    // const userGrroupVal = this.assignUserForm.value.userGroup;
    // const selUserIds = Object.keys(userGrroupVal).filter((i: string) => userGrroupVal[i]);
    // console.log('this.assignUserForm', selUserIds)//.value.filter((i: boolean) => i == true));
    this.sharedService.showLoader('submitAssign', true);
    this.claimsService.assignLiability({
      liabilityId: this.assignUserForm.value.claimId,
      kcgUserId: this.assignUserForm.value.user
    }).subscribe((res) => {
      this.sharedService.showLoader('submitAssign', false);
      this.alertService.showSnackBar({
        message: res?.message ?? "User assigned successfully.",
        status: "success"
      });
      const updateCardAssigneeName = () => {
        const selUser = this.assignUsers.find((i: { kcguserId: string }) => i.kcguserId == this.assignUserForm.value.user);
        this.assignUserForm.value.claim.assignedTo = selUser ? `${selUser.firstname} ${selUser?.lastname}` : '';
      }
      updateCardAssigneeName();
      this.assignClaimTemplateRef.close();
    }, (err) => {
      this.sharedService.showLoader('submitAssign', false);
      this.alertService.showSnackBar({
        message: err?.error?.message ?? "User assignment failed.",
        status: "error"
      });
    })
  }

  async openSendClaimFacts(claim: Claim) {
    this.sharedService.showLoader('openSendClaim', true);
    this.sendClaimFactsTemplateRef = this.modalService.open(this.sendClaimFactsTemplate,
      { centered: true, size: 'sm', backdrop: 'static', keyboard: false });
    this.initSendClaimFactsForm(claim);

    this.sharedService.showLoader('openSendClaim', false);


  }

  initSendClaimFactsForm(claim: Claim) {
    const liabilityId = claim.documentId;
    const cardData = JSON.parse(JSON.stringify(claim.card_data));
    const defendantEmail = cardData.claimInfo.defendantEmail ?? '';
    const defendantPhone = cardData.claimInfo.defendantPhone ?? '';
    const defendantName = cardData.claimInfo.defendantName ?? '';
    this.sendClaimFactsForm = new FormGroup({
      claim: new FormControl(claim, [Validators.required]),
      liabilityId: new FormControl(liabilityId, [Validators.required]),
      defendantName: new FormControl(defendantName, [Validators.required]),
      defendantEmail: new FormControl(defendantEmail, [Validators.required, Validators.email]),
      defendantPhone: new FormControl(defendantPhone, [Validators.pattern('^((\\+?))?[0-9]{6,}$')])
    })
  }

  onSubmitSendClaimFacts() {
    this.loading = true;
    this.sharedService.showLoader('sendClaimFacts', true);
    const { defendantEmail, defendantPhone, defendantName, liabilityId, claim } = this.sendClaimFactsForm.value;
    this.claimsService.sendClaimFacts({ defendantEmail, defendantPhone, defendantName, liabilityId }).subscribe((resSuccess) => {
      this.alertService.showSnackBar({
        message: resSuccess?.message ?? 'Claim facts sent successfully.',
        status: "success"
      });
      claim.card_data.claimInfo.defendantEmail = defendantEmail; //Update card email 
      claim.card_data.claimInfo.defendantPhone = defendantPhone;//
      this.loading = false;
      this.sharedService.showLoader('sendClaimFacts', false);
      this.sendClaimFactsTemplateRef.close();
    }, (resError) => {
      //console.log('resError',resError);
      this.alertService.showSnackBar({
        message: resError?.error?.message ?? 'Claim facts sending failed.',
        status: "error"
      });
      this.loading = false;
      this.sharedService.showLoader('sendClaimFacts', false);
    })

  }

  openDeleteClaim(claim: { documentId: string }) {
    let args = {
      id: claim.documentId
    }
    this.delClaimTemplateRef = this.modalService.open(this.deleteClaimTemplate,
      { centered: true, size: 'sm', backdrop: 'static', keyboard: false }
    );

    this.delClaimTemplateRef.result.then((resSuccess) => {
      if (resSuccess === 'ok') {
        this.sharedService.showLoader('delClaim', true);
        this.claimsService.deleteClaim(args).subscribe(delResSuccess => {
          this.sharedService.showLoader('delClaim', false);
          //console.log('Success', res);
          const claimIndex = this.allClaims.findIndex((i: any) => i.documentId === claim.documentId);
          if (claimIndex != -1) this.allClaims.splice(claimIndex, 1);
          // console.log('hi', );
          this.alertService.showSnackBar({
            message: delResSuccess.message,
            status: "success"
          });

        }, delResError => {
          //console.log('delResError', delResError?.error?.message);
          const errMsg = delResError?.error?.message ?? 'Delete claim failed.';
          this.sharedService.showLoader('delClaim', false);
          this.alertService.showSnackBar({
            message: errMsg,
            status: "error"
          });
        })
      }
    }, (resError) => {
      this.sharedService.showLoader('delClaim', false);
    });

  }

  closeDeleteClaimModal() {
    if (this.delClaimTemplateRef) {
      this.delClaimTemplateRef.close();
    }
  }

  async getFormData(documentId?: string) {
    try {
      const formDataObj: {} = documentId ? { documentId: documentId } : {};
      const res = await this.claimsService.getFormData(formDataObj).toPromise();
      this.claimTypeArr = res.data;
      this.orgClaimFormArr = [];
      res?.additionalParams?.org_data.forEach((param: { title: any; identifier: any; isRequired: any; type: any; userResponse: any }) => {
        this.orgClaimFormArr.push({
          title: param.title,
          identifier: param.identifier,
          isMandatory: param.isRequired,
          fieldType: param.type,
          userResponse: param.userResponse
        })
      })


    } catch (err) {
    }
  }

  onClaimTypeChange(claimTypeVal: string) {
    const claimType = this.claimTypeArr.find(el => el.typeofLoss == claimTypeVal)?.formData;
    this.claimFormArr = claimType ? claimType : [];
    if (this.claimFormArr) this.getDynamicField(this.claimFormArr, this.orgClaimFormArr)
  }

  getDynamicField(claimFormArr: ClaimForm[], orgClaimFormArr: OrgClaimForm[]) {
    //console.log('orgClaimFormArr', orgClaimFormArr);
    const claimFormControl = claimFormArr?.reduce((acc: any, curr: any) => {
      acc = acc || {}
      if (!acc[curr.identifier]) {
        let valArr = [];
        if (curr.isMandatory) valArr.push(Validators.required)
        let userRes = curr?.userResponse ? curr?.userResponse : '';
        if (curr.fieldType === 'date' && userRes) {
          userRes = new Date(`${userRes.month}-${userRes.day}-${userRes.year}`)
          //console.log('userRes',)
        }
        acc[curr.identifier] = [userRes, valArr]
      }
      return acc;
    }, {});

    const orgClaimFormControl = orgClaimFormArr?.reduce((acc: any, curr: any) => {
      acc = acc || {}
      if (!acc[curr.identifier]) {
        let valArr = [];
        if (curr.isMandatory) valArr.push(Validators.required)
        let userRes = curr?.userResponse ? curr?.userResponse : '';
        if (curr.fieldType === 'date' && userRes) {
          userRes = new Date(`${userRes.month}-${userRes.day}-${userRes.year}`)
          //console.log('userRes',)
        }
        acc[curr.identifier] = [userRes, valArr]
      }
      return acc;
    }, {})

    this.newClaimForm.removeControl('dynamicFields');
    this.newClaimForm.removeControl('orgDataFields');
    this.newClaimForm.addControl('dynamicFields', this.fb.group(claimFormControl));
    this.newClaimForm.addControl('orgDataFields', this.fb.group(orgClaimFormControl));
  }


  async openCreateNewClaimForm(event: Event, claim?: Claim) {
    // if(claim) {
    //   e.preventDefault()
    // }
    this.onEditSelectClaim = null;
    if (claim) {
      this.onEditSelectClaim = claim
    }

    const claimDocumentId = claim?.documentId ?? '';
    await this.getFormData(claimDocumentId);
    if (!this.claimTypeArr || !this.claimTypeArr?.length) {
      this.notificationService.showMessage(true, 'error', 'No claim form found under this organisation.')
      return;
    }
    this.initLoad = true;
    //console.log(' this.newClaimTempRef',  this.newClaimTempRef)
    if (this.newClaimTempRef) {
      this.newClaimTempRef.close();
    }

    this.newClaimTempRef = this.modalService.open(this.newClaimTemplate, { centered: true, size: 'sm', backdrop: 'static', keyboard: false });


    //this.getClaimTypeData();
    //console.log('----',this.claimTypeArr );
    let selectedClaimType = this.claimTypeArr.find((val: ClaimType, index) => val.isSelected === true)
    let firstClaimType = this.claimTypeArr.find((val, index) => index === 0)
    selectedClaimType = selectedClaimType ? selectedClaimType : firstClaimType;

    let claimType = selectedClaimType?.typeofLoss;
    this.claimFormArr = selectedClaimType ? selectedClaimType.formData : [];

    this.newClaimForm = this.fb.group({
      'id': [claimDocumentId],
      'claimType': [{ value: claimType, disabled: claimDocumentId ? true : false }, Validators.required],
      'dynamicFields': [''],
      'orgDataFields': ['']
    });

    if (this.claimFormArr.length) this.getDynamicField(this.claimFormArr, this.orgClaimFormArr);
  }

  closeNewClaimModal() {
    if (this.newClaimTempRef) {
      this.newClaimTempRef.close();
    }
  }

  onSubmitClaimForm() {
    this.initLoad = false;
    if (this.newClaimForm.status != 'VALID') return;
    this.sharedService.showLoader('createNewClaim', true);
    const dynamicFields = this.newClaimForm.value.dynamicFields;

    const dateIds = this.claimFormArr.map((i: any) => {
      if (i.fieldType === 'date') {
        return i.identifier;
      }
    });
    for (let field in dynamicFields) {
      if (dateIds.includes(field) && dynamicFields[field]) {
       // dynamicFields[field] = dynamicFields[field].getDate();
        const date = new Date(dynamicFields[field])
        const localeDate = date.toLocaleDateString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        })
        const dateArr = localeDate.split('/');
        const [day, month, year] = dateArr;
        //console.log('dynamicFields[field]', dynamicFields[field], new Date(localeDate), new Date(`${year}-${day}-${month}T00:00:00.000Z`))
        dynamicFields[field] = `${year}-${day}-${month}T00:00:00.000Z`;
      }
    }
    // console.log('success', dynamicFields);
    // return false;

    const reqArg = {
      claimId: this.newClaimForm.value.id,
      claimInfo: JSON.stringify(dynamicFields),
      additionalInfo: JSON.stringify(this.newClaimForm.value.orgDataFields),
      workflowType: this.newClaimForm.getRawValue().claimType
    };
    if (this.newClaimForm.value.id) {
      this.claimsService.updateClaimcard(reqArg).subscribe(res => {
        this.notificationService.showMessage(true, 'success', res.message)
        this.closeNewClaimModal();
        this.sharedService.showLoader('createNewClaim', false);
        if (this.onEditSelectClaim && this.onEditSelectClaim.card_data) {
          this.onEditSelectClaim.card_data.claimInfo = res.data.card_data.claimInfo;
        }
      }, err => {
        this.notificationService.showMessage(true, 'error', err.error.message)
        this.sharedService.showLoader('createNewClaim', false);
      });
    } else {
      this.claimsService.createNewClaim(reqArg).subscribe(res => {
        this.notificationService.showMessage(true, 'success', res.message)
        this.closeNewClaimModal();
        this.viewClaimWizard(res.data.id);
        this.sharedService.showLoader('createNewClaim', false);
      }, err => {
        console.log(err);
        this.notificationService.showMessage(true, 'error', err.error.message)
        this.sharedService.showLoader('createNewClaim', false);
      });
    }
  }

  createNewClaimOld() {
    this.initLoad = false;
    if (this.newClaimForm.status != 'VALID') return;
    this.sharedService.showLoader('createNewClaim', true);
    const requestParams = {
      claimInfo: JSON.stringify(this.newClaimForm.value)
    };
    const newClaim = this.claimsService.createNewClaim(requestParams).subscribe(res => {
      // console.log(res);
      this.notificationService.showMessage(true, 'success', res.message)
      this.closeNewClaimModal();
      // this.resetSearchClaimForm();
      this.viewClaimWizard(res.data.id);
      this.sharedService.showLoader('createNewClaim', false);
    }, err => {
      console.log(err);
      this.notificationService.showMessage(true, 'error', err.error.message)
      this.sharedService.showLoader('createNewClaim', false);
    });
    this.subscriptions.push(newClaim);
  }

  ngOnDestroy(): void {
    document.documentElement.style.height = '100%';
    this.subscriptions.map(subscription => subscription.unsubscribe());
  }

}
