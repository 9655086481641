import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimeoutComponent } from './timeout/timeout.component';
import { appConfig } from 'src/app/config/app.config';
import { UserIdleModule } from 'angular-user-idle';
import { KButtonModule } from 'ng-k-button';



@NgModule({
  declarations: [
    TimeoutComponent
  ],
  imports: [
    CommonModule,
    KButtonModule,
    UserIdleModule.forRoot({ idle: appConfig.userIdle, timeout: appConfig.userIdleTimeOut, ping: appConfig.userIdlePing }),
  ],
  exports:[TimeoutComponent]
})
export class TimeoutModule { }
