<ng-template #timeoutTemplate let-c="close" let-d="dismiss">
    <div class="timeout_modal">
      <div class="modal-header">
        <h4 class="modal-title">{{title}} </h4>
        <button type="button" class="close" aria-label="Close" (click)="closeTimeoutModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>
          You will be logged out within
          <b>{{timeOutTimeLeft}}</b> seconds.
        </p>
      </div>
      <div class="modal-footer">
        <div class="button_holder">
          <k-button [type]="KButtonType.KCGButton" [label]="'Log out'" [subType]="KKcgButtonSubType.Cancel"
            [color]="'dark-grey'" (buttonClick)="logOut()"></k-button>
          <k-button [type]="KButtonType.KCGButton" [label]="'Keep me logged in'"
            [subType]="KKcgButtonSubType.Regular" [color]="'brand-main'" (buttonClick)="closeTimeoutModal()"></k-button>
          <div class="clearfix"></div>
        </div>
      </div>
    </div>
  </ng-template>