<ng-template #passwordTemplate let-c="close" let-d="dismiss">
    <div class="timeout_modal">
        <div class="modal-header">
            <h4 class="modal-title">{{title}} </h4>
            <button *ngIf="!isPasswordExpired" type="button" class="close" aria-label="Close"
                (click)="closePasswordModal()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <form #passFormDir="ngForm" [formGroup]="passwordForm"
                (ngSubmit)="onPasswordSubmit(passwordForm, passFormDir)">


                <div class="form-group">
                    <label>Old Password</label>
                    <input type="password" formControlName="password" autocomplete="on" class="form-control" />
                </div>
                <div class="form-group">
                    <label>New Password</label>
                    <input type="password" formControlName="newPassword" autocomplete="off" class="form-control" />
                </div>
                <div class="form-group">
                    <label>Confirm Password</label>
                    <input type="password" formControlName="confirmPassword" autocomplete="off" class="form-control" />
                    <div *ngIf="passwordForm.controls.confirmPassword.dirty">
                        <div class="text-danger" *ngIf="passwordForm.controls.confirmPassword.errors?.misMatch">
                            Passwords must match</div>
                    </div>
                </div>


                <div class="form-group" *ngIf="passwordError && passwordError.length">
                    <div class="text-danger" *ngFor="let error of passwordError">
                        {{error.message}}
                    </div>
                </div>




                <div class="button_holder text-right">
                    
                    <button class="custom-button custom-button_cancel fill_button  " (click)="logOut()">
                        <span>Log out</span>
                        <i class="fa fa-angle-right"></i>
                    </button>
                    <button type="submit" class="custom-button fill_button "
                        [disabled]="loading || (passFormDir.submitted && !passFormDir.valid) || !passFormDir.valid">
                        <span>Save</span>
                        <i class="fa fa-angle-right"></i>
                    </button> 
                </div>
            </form>
        </div>
    </div>
</ng-template>