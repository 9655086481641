<k-header [logo]="logo" [navItems]="navItems" (click)="onHeaderLogoClick()">
  <k-header-right-section *ngIf="isLoggedIn">
    <span *ngIf="isLoggedIn">
      {{userName}}
    </span>
    <button class="round-button background-transparent user-avatar" style="display: inline-block; top: -3px" #anchor
      (click)="userDropdownClick($event)">
      <k-icon
        [icon]=" { type: 'image', src: userAvatar ? userAvatar : './../assets/avatar-placeholder.jpg', extraClass: 'user-avatar'}">
      </k-icon>
    </button>
    <kendo-popup class="rightSide_list" id="pop-user-options" #popup (anchorViewportLeave)="showUserOptions = false"
      *ngIf="showUserOptions">
      <ng-container *ngFor="let nav of navAccessArr">
        <div *ngIf="nav.access" class="dropdown-item" routerLink="{{nav.routerLink}}"
          (click)="showUserOptions = false">
          <div class="row">
            <div class="col-2 text-center dropdown-item-col">
              <k-icon [icon]="{type: 'fas', name: nav.icon}"></k-icon>
            </div>
            <div class="col-10 dropdown-item-col">
              {{nav.label}}
            </div>
          </div>
        </div>
      </ng-container>
<!-- 
      <div *appAccessPrivilege="accessModulePublicApi" class="dropdown-item" routerLink="settings/public-api"
        (click)="showUserOptions = false">
        <div class="row">
          <div class="col-2 text-center dropdown-item-col">
            <k-icon [icon]="{type: 'fas', name: 'plug'}"></k-icon>
          </div>
          <div class="col-10 dropdown-item-col">
            Public API
          </div>
        </div>
      </div>
      <div *appAccessPrivilege="accessModuleAdminDashboard" class="dropdown-item" routerLink="settings/org-management"
        (click)="showUserOptions = false">
        <div class="row">
          <div class="col-2 text-center dropdown-item-col">
            <k-icon [icon]="{type: 'fas', name: 'user'}"></k-icon>
          </div>
          <div class="col-10 dropdown-item-col">
            Administration
          </div>
        </div>
      </div>
      <div *appAccessPrivilege="accessModuleColorConfig" class="dropdown-item" routerLink="settings/color-config"
        (click)="showUserOptions = false">
        <div class="row">
          <div class="col-2 text-center dropdown-item-col">
            <k-icon [icon]="{type: 'fas', name: 'cog'}"></k-icon>
          </div>
          <div class="col-10 dropdown-item-col">
            Settings
          </div>
        </div>
      </div> -->
      <div class="dropdown-item" (click)="changePassword()">
        <div class="row">
          <div class="col-2 text-center dropdown-item-col">
            <k-icon [icon]="{type: 'fas', name: 'key'}"></k-icon>
          </div>
          <div class="col-10 dropdown-item-col">
            Change Password
          </div>
        </div>
      </div>
      <div class="dropdown-item" (click)="logOut()">
        <div class="row">
          <div class="col-2 text-center dropdown-item-col">
            <k-icon [icon]="{type: 'fas', name: 'sign-out-alt'}"></k-icon>
          </div>
          <div class="col-10 dropdown-item-col">
            Sign Off
          </div>
        </div>
      </div>
    </kendo-popup>
  </k-header-right-section>
</k-header>