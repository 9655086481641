import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AccessModuleT } from '../_models/access-privilege.model';
import { Role } from '../_models/role';
import { AuthenticationService } from '../_services/authentication.service';



@Directive({
  selector: '[appAccessPrivilege]'
})
export class AccessPrivilegeDirective {

    accessModule!: AccessModuleT;

  constructor(
    private authService: AuthenticationService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) { }

  @Input()
  set appAccessPrivilege(accessModule: AccessModuleT) {
    if (!accessModule) {
      throw new Error('Access module is empty or missed');
    }
    this.accessModule = accessModule;
  }

  async ngOnInit() {
    const accessPrivilege = await this.authService.checkAccessPrivilege(this.accessModule); //.then(res=>{
        if(accessPrivilege){
            this.viewContainer.createEmbeddedView(this.templateRef);
        }else{
            this.viewContainer.clear();
        }
  }

}
