import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { RouterModule } from '@angular/router';
import { KFooterModule } from 'ng-k-footer';
import { KHeaderModule } from 'ng-k-header';
import { KIconModule } from 'ng-k-icon';
import { PopupModule } from '@progress/kendo-angular-popup';
import { CustomModule } from '../../_custom/custom.module';



@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    KFooterModule,
    KHeaderModule,
    KIconModule,
    PopupModule,
    CustomModule
  ],
  exports: [HeaderComponent, FooterComponent]
})
export class LayoutModule { }
