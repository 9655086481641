import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Globals } from '../_globals/globals';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    // tslint:disable-next-line: variable-name
    private _g: Globals,
    public apiService: ApiService
  ) { }


  /**
  * addKcgUser
  *
  * @param object requestParam
  *
  * @return object
  */
  public addKcgUser(): Observable<any> {
    return this.apiService.postService(this._g.api.addKcgUser);
  }

  /**
  * updateOrgColorConfig
  *
  * @param object requestParam
  *
  * @return object
  */
  public updateOrgColorConfig(requestParam: any): Observable<any> {
    return this.apiService.postService(this._g.api.updateOrgColorConfig, requestParam);
  }

  /**
    * getOrgColorConfig
    *
    * @param object requestParam
    *
    * @return object
    */
  public getOrgColorConfig(): Observable<any> {
    return this.apiService.getService(this._g.api.getOrgColorConfig);
  }


  /**
    * getAllRightsByToken
    *
    * @param object requestParam
    *
    * @return object
    */
  public getAllRightsByToken(): Observable<any> {
    return this.apiService.getService(this._g.api.getAllRightsByToken);
  }

  /**
    * getAllKcgUsers
    *
    * @param object requestParam
    *
    * @return object
    */
   public getAllKcgUsers(requestParams: {[key: string]: string}): Observable<any> {
    return this.apiService.getService('getAllKcgUsers', requestParams);
  }
}
