import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { OAuthService } from 'angular-oauth2-oidc';
import { Observable, of } from 'rxjs';
import { AccessModuleT } from '../_models/access-privilege.model';
import { KcgService } from './kcg.service';
import { UserService } from './user.service';
import { take } from 'rxjs/operators';
import { SharedService } from './shared.service';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {

    userRightsData: any;
    basicUserData: any;
    checkUserRights = true;
    //lockUserRightsCall = false;

    constructor(
        private router: Router,
        private oauthService: OAuthService,
        private kcgService: KcgService,
        private userService: UserService,
        private sharedService: SharedService
    ) { }

    logOut() {
        //sthis.oauthService.revokeTokenAndLogout();
        this.oauthService.logOut();
    }

    getLoggedInData() {
        return this.oauthService.getIdentityClaims();
    }

    tryLogin() {
        return this.oauthService
            .loadDiscoveryDocumentAndTryLogin()
    }


    getUserRole() {
        return null;
        //return 'Super Admin';//Will be updated with dynamic data
    }

    async getUserRightsData() {
        try {
           // if(this.lockUserRightsCall) return null;
            if (this.userRightsData) return this.userRightsData;
            this.sharedService.showLoader('userRights', true);
            //this.lockUserRightsCall = true;
            const userRights = await this.userService.getAllRightsByToken().toPromise();
            //this.lockUserRightsCall = false;
            this.sharedService.showLoader('userRights', false);
            this.userRightsData = userRights.data;
            return this.userRightsData;
        } catch (err) {
            //this.lockUserRightsCall = false;
            this.sharedService.showLoader('userRights', false);
            return false;
        }
    }


    /**
     * getUserPrivileges
     * 
     * @returns 
     */
    async getUserPrivileges() {
        if(this.checkUserRights) return await this.getUserRightsData();
        const data = {
            'Claim': ['Read', 'Write', 'Assign', 'Delete'],
            'ClaimSummary': ['Read','Write'],
            'ClaimDetail': ['Read'],
            'ClaimCards': ['Read'],
            'ColorSettings': ['Read', 'Write'],
            'PublicApiSettings': ['Read', 'Write']
        };
        return data;
    }

    async checkAccessPrivilege(accessModule: AccessModuleT) {
        //console.log('---access', accessModule);
        const userPrivileage: any = await this.getUserPrivileges();
        if (!accessModule?.moduleKey) return true; //console.log('---0---', accessModule.moduleKey, userPrivileage, userPrivileage[accessModule.moduleKey]);
        if (!userPrivileage || !userPrivileage[accessModule.moduleKey] || !userPrivileage[accessModule.moduleKey].length) return false;
        const isAccess = userPrivileage[accessModule.moduleKey].some((x: string) => x == accessModule.moduleComponent)
        //console.log('---isAcces-----', isAccess);
        return isAccess;
    }

    /**
     * getKcgUserBasic
     * 
     * @returns 
     */

    async getKcgUserBasic() {
        try {
            if (this.basicUserData) return this.basicUserData;
            const basicUserData = await this.kcgService.basicUserAuthorization().toPromise();
            this.basicUserData = basicUserData.data;
            return this.basicUserData;
        } catch (ex) {
            return null;
        }
    }

}
