import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AccessModules, AccessModuleComponents } from '../_models/access-privilege.model';

// import { UserService } from '../_services/user.service';
import { AuthenticationService } from './authentication.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authService: AuthenticationService
    ) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {

        const tokenData = this.authService.getLoggedInData();
        if (!tokenData) return this.authService.tryLogin();
        const accessModule = {
            moduleKey: route.data.moduleKey,
            moduleComponent:  route.data.moduleComponent
        }
        return this.authService.checkAccessPrivilege(accessModule);

        // .pipe(map((res) => {
        //     const accessModule = {
        //         moduleKey: route.data.moduleKey,
        //         moduleComponent: AccessModuleComponents.read
        //     }
        //     const userPrivileage: any = res.data;
        //     if (!accessModule.moduleKey) return true;
        //     if (!userPrivileage[accessModule.moduleKey] || !userPrivileage[accessModule.moduleKey].length) return false;
        //     const isAccess = userPrivileage[accessModule.moduleKey].some((x: string) => x === accessModule.moduleComponent)
        //     console.log('---isAcces-----', isAccess);
        //     return isAccess;
        // }))



        // const tokenData = this.authService.getTokenData();
        // if (!tokenData) return this.authService.oauthInitLog();

        // const accessModule = {
        //     moduleKey: route.data.moduleKey,
        //     moduleComponent: AccessModuleComponents.read
        // }

        // this.authService.checkAccessPrivilege(accessModule)


        // const userRole = this.authService.getUserRole();
        // if (route.data.roles && route.data.roles.indexOf(userRole) === -1) {
        //     this.router.navigate(['/']);
        //     return false;
        // }
        // return true; 

        // return this.authService.loadUserProfile().then(profileRes => {
        //     if (profileRes) {
        //         return true;
        //     } else {
        //         this.router.navigate(['/']);
        //         // // not logged in so redirect to login page with the return url
        //         // this.router.navigate(['/klogin'], {
        //         //     queryParams: {
        //         //     returnUrl: state.url
        //         //     }
        //         // });
        //         return false;
        //     }
        // })
    }


}
