import { Component, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { IKnavNavItem } from 'ng-k-header';
import { KTheme } from 'ng-k-styles';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { KcgService } from 'src/app/_services/kcg.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { timer } from 'rxjs';
import { flatMap, take, takeWhile, takeUntil } from 'rxjs/operators';
import { SharedService } from 'src/app/_services/shared.service';
import { UserService } from 'src/app/_services/user.service';
import { AccessModules, AccessModuleComponents, AccessModuleT } from 'src/app/_models/access-privilege.model';
import { PasswordService } from '../../password/password.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {


  public logo: any = {
    type: 'src',
    value: '../assets/images/smartclaim_logo.png'
  };
  public showUserOptions: boolean = false;
  public loggedUser: any = {};
  public navItems: IKnavNavItem[] = [];
  userName!: string;
  userAvatar!: string;
  userRole!: string;
  userInfo: any = {};
  isLoggedIn!: boolean;

  accessModuleColorConfig: AccessModuleT = { moduleKey: AccessModules.colorSettings, moduleComponent: AccessModuleComponents.read };
  accessModulePublicApi: AccessModuleT = { moduleKey: AccessModules.publicApiSettings, moduleComponent: AccessModuleComponents.read };
  accessModuleAdminDashboard: AccessModuleT = { moduleKey: AccessModules.adminDashboard, moduleComponent: AccessModuleComponents.read };
  pathWithoutAuth = ['login']
  pathOtpAuth = ['oClaim']

  colorConfigAccess: boolean = false;
  publicApiAccess: boolean = false;
  adminAccess: boolean = false;

  navAccessArr = [ {
      access: this.publicApiAccess,
      accessModule: this.accessModulePublicApi,
      label: 'Public API',
      routerLink: ['/settings/public-api'],
      icon: 'plug'
    },{
      access: this.publicApiAccess,
      accessModule: this.accessModulePublicApi,
      label: 'BRB Usecase Management',
      routerLink: ['/settings/brb-use-case'],
      icon: 'briefcase'
    },{
      access: this.adminAccess,
      accessModule: this.accessModuleAdminDashboard,
      label: 'Administration',
      routerLink: ['/settings/org-management'],
      icon: 'user'
    }, {
      access: this.colorConfigAccess,
      accessModule: this.accessModuleColorConfig,
      label: 'Settings',
      routerLink: ['/settings/color-config'],
      icon: 'cog'
    }
  ]


  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private oauthService: OAuthService,
    private kcgService: KcgService,
    private sharedService: SharedService,
    private passwordService: PasswordService
  ) {
  }

  ngOnInit(): void {
    this.checkPageType()
    this.getLoggedStatus();
    (async () => {
      await this.loadNavItems();
    })()

    // console.log('init',this.logo)
  }

  async loadNavItems() {
    for(let nav of this.navAccessArr){
      const checkAccess = await this.authenticationService.checkAccessPrivilege(nav.accessModule);
      if (checkAccess) {
        nav.access = true;
        this.navItems.push({ label: nav.label, routerLink: nav.routerLink});
      }
    }
   // console.log('---',this.navAccessArr);

    // this.publicApiAccess = await this.authenticationService.checkAccessPrivilege(this.accessModulePublicApi);
    // if (this.publicApiAccess) {
    //   this.navItems.push({ label: 'Public API', routerLink: ['/settings/public-api'] });
    // }

    // this.adminAccess = await this.authenticationService.checkAccessPrivilege(this.accessModuleAdminDashboard);
    // if (this.      ) {
    //   this.navItems.push({ label: 'Administration', routerLink: ['/settings/org-management'] });
    // }

    // this.colorConfigAccess = await this.authenticationService.checkAccessPrivilege(this.accessModuleColorConfig);
    // if (this.colorConfigAccess) {
    //   this.navItems.push({ label: 'Settings', routerLink: ['/settings/color-config'] });
    // }

  }


  /**
   * checkPageType
   * checking page type whether it falls under kcg auth page, otp auth page or non auth page
   */
  checkPageType() {

    // console.log(this.router.url);
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        let currentUrl: string = event.url;
        currentUrl = currentUrl.split('/')[1];
        const loggedInData = this.authenticationService.getLoggedInData();

        if (this.pathWithoutAuth.includes(currentUrl)) {
          this.logo.routerLink = ['/login']
        }
        else if (this.pathOtpAuth.includes(currentUrl)) {
          this.logo.routerLink = ['/oClaim']
        }
        else {
          this.logo.routerLink = ['/dashboard']
        }

      }
    });
  }

  public userDropdownClick($event: any) {
    $event.stopPropagation();
    this.showUserOptions = !this.showUserOptions;
    setTimeout(() => {
      if (this.showUserOptions) {
        const popElement: any = document.getElementById(`pop-user-options`);
        document.body.appendChild(popElement);
      }
    });
  }

  public async getUserInfo() {
    try {
      // const userInfo = await this.kcgService.basicUserAuthorization().toPromise();
      const userInfo = await this.authenticationService.getKcgUserBasic();
      if (userInfo) {
        this.userName = (userInfo.givenName ? userInfo.givenName : '') + (userInfo.familyName ? ' ' + userInfo.familyName : '');
        this.userAvatar = userInfo.userPictures ? userInfo.userPictures[0].pictureUrl : '';
      }
      // this.userRole = this.authenticationService.getUserRole();
      return { userName: this.userName, userAvatar: this.userAvatar }
    } catch (error) {
      return {};
    }
  }

  // public async getAllRightsByToken(): Promise<void> {
  //   try {
  //     const userInfo = await this.userService.getAllRightsByToken().toPromise();
  //     //console.log('---us', userInfo);
  //     return;
  //   } catch (error) {
  //     return;
  //   }
  // }


  public getLoggedStatus(): void {
    const isValid = this.oauthService.hasValidIdToken() && this.oauthService.hasValidAccessToken();
    this.sharedService.newUserLoggedModel$.subscribe((userlogged) => {
      this.isLoggedIn = userlogged ? true : false;
      if (this.isLoggedIn) this.getUserInfo();
    });
    this.isLoggedIn = isValid;
    if (this.isLoggedIn) this.getUserInfo();
  }

  public logOut(): void {
    this.showUserOptions = false;
    this.sharedService.showLoader('Logout', true);
    setTimeout(() => this.sharedService.onUserLogged(false), 20)
    this.authenticationService.logOut();
  }

  changePassword(): void {
    this.showUserOptions = false;
    this.passwordService.showChangePassword();
  }


  onHeaderLogoClick(){
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
  }
    this.router.onSameUrlNavigation = 'reload';
    // this.router.navigate(['/dashboard'], { queryParams: { index: 1 } });
  }
}
