export enum AccessModules {
    colorSettings = 'ColorSettings',
    publicApiSettings = 'PublicApiSettings',
    claim = 'Claim',
    claimCards = 'ClaimCards',
    claimSummary = 'ClaimSummary',
    claimDetail = 'ClaimDetail',
    adminDashboard = 'AdminDashboard',
    orgFilter = 'OrgFilter'
}

export enum AccessModuleComponents {
    read = 'Read',
    write = 'Write',
    assign = 'Assign',
    delete = 'Delete'
}

export type AccessModuleT = {
    moduleKey: string,
    moduleComponent: string
}