import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private notificationFlag = new Subject<object>();
  notificationFlag$ = this.notificationFlag.asObservable();

  /**
    * showMessage
    *
    * @param boolean displayMessage
    * 
    * @param string msgType
    * 
    * @param string message
    * 
    * @param number timeout
    *
    * @return object
    */
  public showMessage(displayMessage: boolean, msgType: string, message: string, timeout?: number) {
    let notification = {};
    notification = {
      displayMessage,
      msgType,
      message,
      timeout: timeout ?? 4000
    };
    this.notificationFlag.next(notification);
    setTimeout(() => {
      this.notificationFlag.next({});
    }, timeout ?? 4000);
  }

}
