import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PasswordUpdateComponent } from './password-update/password-update.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';



@NgModule({
  declarations: [PasswordUpdateComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [PasswordUpdateComponent]
})
export class PasswordModule { }
