import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { RoleAccessDirective } from '../_directives/role-access.directive';
import { AccessPrivilegeDirective } from '../_directives/access-privilege.directive';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [RoleAccessDirective, AccessPrivilegeDirective],
  exports: [RoleAccessDirective, AccessPrivilegeDirective]
})
export class CustomModule { }
