import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';


export type TimeOut = {
  init: boolean
}

@Injectable({
  providedIn: 'root'
})
export class TimeoutService {

  private timeoutSubject = new BehaviorSubject<TimeOut>({init: false});
  public timeoutSubject$ = this.timeoutSubject.asObservable();

  constructor() { }

  timeoutInit(params: TimeOut){
    this.timeoutSubject.next({init: true});
  }
}
