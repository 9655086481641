import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

/**
 * API Service
 */
@Injectable({
    providedIn: 'root',
})
export class ApiService {
    private apiUrl = environment.apiUrl;

    /**
     * Constructor
     */
    constructor(private http: HttpClient) { }

    /**
     * Get Service
     */
    public getService(method: string, requestParam?: { [key: string]: string }, apiUrl?: string, responseType?: { [key: string]: object }): Observable<any> {
        let queryString = '';
        if (requestParam && Object.keys(requestParam).length) {
            queryString = '?';
            Object.keys(requestParam).forEach(function (currentValue, index) {
                if (currentValue && index != 0) {
                    queryString += '&';
                }
                queryString += currentValue + '=' + encodeURIComponent(requestParam[currentValue]);
            });
        }
        const URL = (apiUrl ? apiUrl : this.apiUrl) + method + queryString;
        responseType = responseType ? responseType : {};
        return this.http.get(URL, responseType);
    }



    /**
     * Post Service
     */
    public postService(method: string, requestParam?: { [key: string]: any }, apiUrl?: string): Observable<any> {
        let formData = new FormData();
        if (requestParam && Object.keys(requestParam).length) {
            Object.keys(requestParam).forEach(function (currentValue, index) {
                formData.append(currentValue, requestParam[currentValue]);
            });
        }
        const URL = (apiUrl ? apiUrl : this.apiUrl) + method;
        return this.http.post(URL, formData);
    }

    /**
     * Put Service
     */
    public putService(method: string, requestParam?: { [key: string]: any },  apiUrl?: string, requestParamAsObj?: { [key: string]: any }): Observable<any> {
        let formData = new FormData();
        if (requestParam && Object.keys(requestParam).length) {
            Object.keys(requestParam).forEach(function (currentValue, index) {
                formData.append(currentValue, requestParam[currentValue]);
            });
        }
        const reqData = requestParamAsObj ? requestParamAsObj : formData;
        const URL = (apiUrl ? apiUrl : this.apiUrl) + method;
        return this.http.put(URL, reqData);
    }

    /**
     * Delete Service
     */
    public deleteService(method: string, requestParam?: { [key: string]: string }): Observable<any> {
        let queryString = '';
        if (requestParam && Object.keys(requestParam).length) {
            queryString = '?';
            Object.keys(requestParam).forEach(function (currentValue, index) {
                if (currentValue && index != 0) {
                    queryString += '&';
                }
                queryString += currentValue + '=' + encodeURIComponent(requestParam[currentValue]);
            });
        }
        const URL = this.apiUrl + method + queryString;
        return this.http.delete(URL);
    }

}
