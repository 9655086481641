<!-- PAGE TITLE -->
<div class="container-fluid page_title">
  <div class="col-xs-12">
    <h3>Claim Dashboard </h3>
  </div>
</div>
<!-- PAGE TITLE END -->
<div class="container-fluid">
  <div class="page_space">
    <ng-container *ngIf="!claimCardsAccess">
      <div class="no_data_msg">No access to view claims</div>
    </ng-container>
    <ng-container *ngIf="claimCardsAccess">
      <ng-container *appAccessPrivilege="accessModuleClaimCards">
        <div class="dash_filterForm">
          <form name="searchClaimsFilterForm" [formGroup]="searchClaimsFilterForm"
            (ngSubmit)="submit_searchClaimsFilterForm(searchClaimsFilterForm)">

            <div class="row">
              <div class="col">
                <div class="row">
                  <div class="col-12 col-md-4 col-lg-3 pr-md-0">
                    <div class="form-group">
                      <input type="text" class="form-control rounded-0" formControlName="keyword"
                        placeholder="Search" />
                    </div>
                  </div>
                  <div class="col-12 col-md-4 col-lg-3 pr-md-0">
                    <div class="form-group">
                      <select class="custom-select form-control rounded-0" formControlName='claimCategory'>
                        <option value="all">All Claims</option>
                        <option value="my">Created Claims</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4 col-xl-3 pr-md-0" *appAccessPrivilege="accessModuleOrgFilter">
                    <div class="form-group">
                      <select class="custom-select form-control rounded-0" formControlName='orgCategory'>
                        <option value="" selected>Select Organization</option>
                        <option *ngFor="let i of organisationList" [value]="i.id">
                              {{i.title}}
                        </option>
                      </select>
                    </div>
                  </div>
                  
                  <div class="col-12 col-md-auto pr-md-0">
                    <div class="form-group">

                      <button type="button" class="btn btn-light float-left resetBtn mr-3"
                        (click)="resetSearchClaimForm(searchClaimsFilterForm)" title="Reset">
                        <i class="fa fa-refresh" aria-hidden="true"></i>
                      </button>
                      <button type="Submit" title="Search"
                        class=" custom-button custom-button_sm fill_button float-right float-md-left">
                        <span>Search</span>
                        <i class="fa fa-search" aria-hidden="true"></i>
                      </button>
                      <div class="clearfix"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-auto claimBtnHolder_col">
                <div class="claimBtnHolder">
                  <button *appAccessPrivilege="accessModuleWrite" type="button" title="New Claim"
                    (click)="openCreateNewClaimForm($event)" class="custom-button custom-button_sm fill_button float-right ">
                    <span>New Claim</span>
                    <i class="fa fa-plus"></i>
                  </button>
                  <div class="clearfix"></div>
                </div>
              </div>
            </div>

          </form>
        </div>
        <div class="no_data_msg" *ngIf="this.allClaims?.length === 0">
          No data found
        </div>
        <div class="row" infiniteScroll [infiniteScrollDistance]="scrollDistance"
          [infiniteScrollUpDistance]="scrollUpDistance" [infiniteScrollThrottle]="throttle" (scrolled)="onScroll()">
          <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 iQcustom-col-2 iQanimated bounceIn"
            *ngFor="let claim of this.allClaims">
            <div class="card iQcard item-hover-effect" [ngClass]="{'iQcardOverlay': claim['status'] == 0}">
              <div class="iQcard_header">
                <div class="row">
                  <div class="col">
                    <span class="time_date_info">
                      <span *ngIf="claim['createdAt']" title="Created Date"><i class="fa-regular fa-clock mr-1"
                          aria-hidden="true"></i>
                        {{claim['createdAt'] | date: 'dd/MM/yyyy' }}
                      </span>
                    </span>
                  </div>
                  <div class="col-auto pl-0">

                    <ul class="card_more_dropdown float-right">
                      <li>
                        <a>
                          <span class="fa-stack ">
                            <i class="fa fa-circle fa-stack-2x"></i>
                            <i class="fa fa-cogs fa-stack-1x fa-inverse"></i>
                          </span>
                        </a>
                        <ul>
                          <li *appAccessPrivilege="accessModuleWrite" (click)="openCreateNewClaimForm($event, claim)">
                            <svg class="brand_icon brand_icon_14 mr-2" fill="#4d4d4d">
                              <use xlink:href="assets/svg_sprite.svg#edit_claim_info"></use>
                            </svg>
                            Edit Claim Info
                          </li>
                          <li *appAccessPrivilege="accessModuleWrite" (click)="viewClaimWizard(claim['documentId'])">
                            <svg class="brand_icon brand_icon_14 mr-2" fill="#4d4d4d">
                              <use xlink:href="assets/svg_sprite.svg#edit"></use>
                            </svg>
                            Edit Claim facts
                          </li>
                          <!-- <li (click)="openSendClaimFacts(claim)">
                            <svg class="brand_icon brand_icon_14 mr-2" fill="#4d4d4d">
                              <use xlink:href="assets/svg_sprite.svg#send_claim_facts"></use>
                            </svg>
                            Send Claim Facts
                          </li> -->
                          <li *appAccessPrivilege="accessModuleClaimSummary" (click)="viewClaim(claim['documentId'])">
                            <svg class="brand_icon brand_icon_14 mr-2" fill="#4d4d4d">
                              <use xlink:href="assets/svg_sprite.svg#liability_decision"></use>
                            </svg>
                            Liability Decision
                          </li>

                          <ng-container *ngIf="claim['isActionAllowed']">
                            <li *appAccessPrivilege="accessModuleAssign" (click)="openAssignClaim(claim)">
                              <svg class="brand_icon brand_icon_14 mr-2" fill="#4d4d4d">
                                <use xlink:href="assets/svg_sprite.svg#assign_to_user"></use>
                              </svg>
                              Assign to User
                            </li>
                          </ng-container>
                          <!-- <li (click)="viewClaim(claim['documentId'])">
                        <i class="fa fa-pencil fa-fw"></i>
                        Summary View
                      </li> -->
                          <!--*appRoleAccess="accessibleRoles" -->
                          <!-- <ng-container *ngIf="claim['isActionAllowed']"> -->
                          <!-- NOTE: Without Delete Claims Validation check -->
                          <ng-container>
                            <li *appAccessPrivilege="accessModuleDelete" (click)="openDeleteClaim(claim)">
                              <svg class="brand_icon brand_icon_14 mr-2" fill="#4d4d4d">
                                <use xlink:href="assets/svg_sprite.svg#delete"></use>
                              </svg>
                              Delete Claim
                            </li>
                          </ng-container>
                        </ul>
                      </li>
                    </ul>


                  </div>
                </div>
              </div>
              <div class="iQcard_body pt-0">

                <ng-container
                  *ngIf="claim?.card_data?.claimInfo?.referanceNumber && !claim?.card_data?.claimInfo?.causeCode">
                  <ng-template [ngTemplateOutlet]="templateCardInfo" [ngTemplateOutletContext]="
                {
                  title: 'Reference Number',
                  content: claim?.card_data?.claimInfo?.referanceNumber,
                  icon:'assets/svg_sprite.svg#reference_number',
                  isBold: 'true'
                }">
                  </ng-template>
                </ng-container>

                <ng-template *ngIf="claim?.card_data?.claimInfo?.causeCode" [ngTemplateOutlet]="templateCardInfo"
                  [ngTemplateOutletContext]="
                {
                  title: 'Cause Code',
                  content: claim?.card_data?.claimInfo?.causeCode,
                  icon:'assets/svg_sprite.svg#reference_number',
                  isBold: 'true'
                }">
                </ng-template>

                <ng-template [ngTemplateOutlet]="templateCardInfo" [ngTemplateOutletContext]="
                {
                  title:claim?.assignedTo ? claim?.assignedTo : 'Unassigned',
                  content: claim?.assignedTo ? claim?.assignedTo : 'Unassigned',
                  icon:'assets/svg_sprite.svg#assign_to_user_ring',
                  spanClass: claim?.assignedTo
                }">
                </ng-template>

                <ng-template [ngTemplateOutlet]="templateCardInfo" [ngTemplateOutletContext]="
                {
                  title:'Claimant Name',
                  content: claim?.card_data?.claimInfo?.claimantName,
                  icon:'assets/svg_sprite.svg#claimant_name'
                }">
                </ng-template>

                <ng-template [ngTemplateOutlet]="templateCardInfo" [ngTemplateOutletContext]="
                {
                  title:'Defendant Name',
                  content: claim?.card_data?.claimInfo?.defendantName,
                  icon:'assets/svg_sprite.svg#claimant_solicitor'
                }">
                </ng-template>
                <ng-template [ngTemplateOutlet]="templateCardInfo" [ngTemplateOutletContext]="
                {
                  title:'Claimant Solicitor',
                  content: claim?.card_data?.claimInfo?.claimantSolicitor,
                  icon:'assets/svg_sprite.svg#claimant_solicitor_court'
                }">
                </ng-template>

                <ng-template [ngTemplateOutlet]="templateCardInfo" [ngTemplateOutletContext]="
                {
                  title:'Claim Type',
                  content: claim?.claimType,
                  icon:'assets/svg_sprite.svg#claim_type'
                }">
                </ng-template>

                <div class="iQcard_field">
                  <span class="brand_icon brand_icon_28" placement="right" title="Claim Date">
                    <svg class="svg_icons">
                      <use xlink:href="assets/svg_sprite.svg#calendar_ring"></use>
                    </svg>
                  </span>
                  <span class="iQcard_field-text text-truncate">
                    <span placement="top" title="Claim Date">
                      {{claim?.card_data?.claimInfo?.claimDate | date: 'dd/MM/yyyy'  }}
                    </span>
                  </span>
                </div>

              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>

<!-- Modal -->
<div class="containerSpace">
  <ng-template #deleteClaimTemplate let-modal>
    <div class="timeout_modal smartClaimModal">
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Confirm</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('close')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <span>Are you sure you want to delete this claim?</span>
        </div>
      </div>
      <div class="modal-footer">
        <div class="button_holder">
          <button type="button" title="Delete Claim" (click)="modal.close('ok')"
            class="custom-button custom-button_sm  fill_button float-right ml-2">
            <span>Delete</span>
            <i class="fa fa-angle-right"></i>
          </button>
          <button type="button" title="Cancel" (click)="modal.dismiss('close')"
            class="custom-button custom-button_sm custom-button_cancel fill_button float-right ml-2">
            <span>Cancel</span>
            <i class="fa fa-angle-right"></i>
          </button>
          <div class="clearfix"></div>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #assignClaimTemplate let-modal>
    <div class="timeout_modal smartClaimModal">
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Assign User</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('close')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <form [formGroup]="assignUserForm" #formDir="ngForm" (ngSubmit)="onSubmitAssign()">

            <!-- <div *ngFor="let user of assignUsers" formGroupName="userGroup">
              <input type="checkbox" [formControlName]="user.id" />
              {{user.name}}
            </div> -->
            <div class="form-group">
              <select class="custom-select form-control rounded-0" formControlName='user'>
                <option value="" selected>--Select User--</option>
                <option *ngFor="let user of assignUsers" [ngValue]="user.kcguserId">
                  {{ user.firstname }} {{user?.lastname}}
                </option>
              </select>
            </div>


            <div class="button_holder">
              <button [disabled]="(formDir.submitted && !assignUserForm.valid) || !assignUserForm.valid" type="submit"
                title="Save" class="custom-button custom-button_sm  fill_button float-right ml-2">
                <span>Save</span>
                <i class="fa fa-angle-right"></i>
              </button>
            </div>
          </form>
        </div>
      </div>
      <div class="modal-footer">
      </div>
    </div>
  </ng-template>



  <ng-template #sendClaimFactsTemplate let-modal>
    <div class="timeout_modal smartClaimModal">
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Send Claim Facts</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('close')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group">

          <form [formGroup]="sendClaimFactsForm" #formDir="ngForm" (ngSubmit)="onSubmitSendClaimFacts()">

            <div class="form-group">
              <label>Email <span style="color: red;">*</span></label>
              <div class="input-group">
                <input type="email" class="form-control" formControlName="defendantEmail">
              </div>
            </div>

            <div class="form-group">
              <label>Phone </label>
              <div class="input-group">
                <input type="tel" class="form-control" formControlName="defendantPhone">
              </div>
            </div>


            <div class="button_holder">
              <button
                [disabled]="loading || (formDir.submitted && !sendClaimFactsForm.valid) || !sendClaimFactsForm.valid"
                type="submit" title="Save" class="custom-button custom-button_sm  fill_button float-right ml-2">
                <span>Send Invite</span>
                <i class="fa fa-angle-right"></i>
              </button>
            </div>
          </form>

        </div>
      </div>
      <div class="modal-footer">
      </div>
    </div>
  </ng-template>

  <ng-template #newClaimTemplate let-c="close" let-d="dismiss">
    <div class="timeout_modal smartClaimModal">
      <div class="modal-header">
        <h5 class="modal-title">Add claim details</h5>
        <button type="button" class="close" aria-label="Close" (click)="closeNewClaimModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form name="newClaimForm" [formGroup]="newClaimForm" #formDir="ngForm" (ngSubmit)="onSubmitClaimForm()">


          <div class="form-group">
            <label>Claim Type <span style="color: red;">*</span></label>
            <div class="input-group">

              <select *ngIf="claimTypeArr.length" class="custom-select form-control rounded-0"
                formControlName='claimType' (ngModelChange)="onClaimTypeChange($event)">
                <option *ngFor="let claimType of claimTypeArr" [value]="claimType.typeofLoss">
                  {{claimType.title}}
                </option>
              </select>
            </div>
          </div>


          <ng-container *ngIf="claimFormArr.length">
            <ng-container *ngFor="let claimField of claimFormArr" formGroupName="dynamicFields">
              <div *ngIf="claimField.identifier && claimField.title" class="form-group">
                <label>
                  {{claimField.title}}
                  <span *ngIf="claimField.isMandatory" style="color:red;"> * </span>
                </label>
                <div class="input-group">
                  <ng-container *ngIf="claimField.fieldType == 'string'">
                    <input type="text" class="form-control" [formControlName]="claimField.identifier">
                  </ng-container>
                  <ng-container *ngIf="claimField.fieldType == 'number'">
                    <input type="number" class="form-control" [formControlName]="claimField.identifier">
                  </ng-container>
                  <ng-container *ngIf="claimField.fieldType == 'date'">
                    <mat-form-field style="width: 100%;" appearance="fill">
                      <mat-label>dd/mm/yyyy</mat-label>
                      <input [formControlName]="claimField.identifier" matInput [matDatepicker]="picker">
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                  </ng-container>
                </div>
                <div class="help-block"
                  *ngIf="newClaimForm.controls['dynamicFields']?.controls[claimField.identifier]?.status === 'INVALID' && !initLoad">
                  <ng-container
                    *ngIf="newClaimForm.controls['dynamicFields']?.controls[claimField.identifier].hasError('required')">
                    {{ claimField.title + ' is required'}}
                  </ng-container>
                </div>
              </div>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="orgClaimFormArr.length">
            <ng-container *ngFor="let claimField of orgClaimFormArr" formGroupName="orgDataFields">
              <div *ngIf="claimField.identifier && claimField.title" class="form-group">
                <label>
                  {{claimField.title}}
                  <span *ngIf="claimField.isMandatory" style="color:red;"> * </span>
                </label>
                <div class="input-group">
                  <ng-container *ngIf="claimField.fieldType == 'text'">
                    <input type="text" class="form-control" [formControlName]="claimField.identifier">
                  </ng-container>
                  <ng-container *ngIf="claimField.fieldType == 'number'">
                    <input type="number" class="form-control" [formControlName]="claimField.identifier">
                  </ng-container>
                  <ng-container *ngIf="claimField.fieldType == 'date'">
                    <mat-form-field style="width: 100%;" appearance="fill">
                      <mat-label>dd/mm/yyyy</mat-label>
                      <input [formControlName]="claimField.identifier" matInput [matDatepicker]="picker">
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                  </ng-container>
                </div>
                <div class="help-block"
                  *ngIf="newClaimForm.controls['orgDataFields']?.controls[claimField.identifier]?.status === 'INVALID' && !initLoad">
                  <ng-container
                    *ngIf="newClaimForm.controls['orgDataFields']?.controls[claimField.identifier].hasError('required')">
                    {{ claimField.title + ' is required'}}
                  </ng-container>
                </div>
              </div>
            </ng-container>
          </ng-container>

          <div class="button_holder">
            
            <button class="custom-button   fill_button float-right ml-2" type="submit" value="Submit">
              <span>Save</span>
              <i class="fa fa-angle-right"></i>
            </button>
            <button class="custom-button  custom-button_cancel fill_button float-right ml-2"
              type="button" value="Cancel" (click)="closeNewClaimModal()">
              <span>Cancel</span>
              <i class="fa fa-angle-right"></i>
            </button>
          </div>


 


        </form>
      </div>
      <!-- <div class="modal-footer">
       <div class="button_holder"> 
       <k-button class="kcg_sm_custom_btn" [type]="KButtonType.KCGButton" [label]="'Cancel'"
            [subType]="KKcgButtonSubType.Cancel" [color]="'dark-grey'" (buttonClick)="closeNewClaimModal()"></k-button>
          <k-button class="kcg_sm_custom_btn" [type]="KButtonType.KCGButton" [label]="'Save'"
            [subType]="KKcgButtonSubType.Regular" [color]="'brand-main'" ></k-button>    
          <div class="clearfix"></div>
        </div>
      </div>-->
    </div>
  </ng-template>
</div>
<!-- Modal End -->



<!---Template for card info  --->
<ng-template #templateCardInfo let-title="title" let-content="content" let-icon="icon" let-spanClass="spanClass"
  let-isBold="isBold">
  <div class="iQcard_field" [ngClass]="{'mb-2' : isBold == 'true'}">
    <span class="brand_icon brand_icon_28" [ngClass]="{assigned: spanClass}" placement="right" [title]="title">
      <svg class="svg_icons">
        <use [attr.xlink:href]="icon"></use>
      </svg>
    </span>
    <span class="iQcard_field-text text-truncate" [ngClass]="{'font-weight-bold': isBold =='true'}">
      <span placement="top" [title]="title">
        {{content}}
      </span>
    </span>
  </div>
</ng-template>