import { ChangeDetectorRef, Component, OnDestroy, OnInit, Renderer2, TemplateRef, ViewChild } from '@angular/core';
import { Router, NavigationEnd, RouteConfigLoadEnd, NavigationStart, ActivatedRoute } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { AuthenticationService } from './_services/authentication.service';
import { NotificationService } from './_services/notification.service';
import { SharedService } from './_services/shared.service';
import { Subscription } from 'rxjs';
import { UserService } from './_services/user.service';
import { OauthPlusService } from './_services/oauth-plus.service';
import { TimeoutService } from './_shared/timeout/timeout.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  public isLoaderOn = false;
  public subscriptions: Subscription[] = [];

  public notification: any = {};
  isLoggedIn: boolean = false;
  pathWithoutAuth = ['login', 'otp'];
  pathWithoutHeaderFooter = ['uniclaim'];

  showContainer: boolean = false;
  //availableAppAuthTypes = ['kcg','otp'];
  //currentAppAuthType = '';
  disableSections: any[] = [];
  // showHeader: boolean = true;
  // showFooter: boolean = true;
  showAsPlugin: boolean = false;

  constructor(
    private router: Router,
    public sharedService: SharedService,
    private oauthService: OAuthService,
    public authenticationService: AuthenticationService,
    public changeDetectorRef: ChangeDetectorRef,
    public notificationService: NotificationService,
    public userService: UserService,
    private oauthPlusService: OauthPlusService,
    private timeoutService: TimeoutService,
    private renderer: Renderer2
  ) {
    // this.oauthPlusService.configure();
    this.initialRouterCheck();
    this.configureOAuth();
  }

  
  ngOnInit(): void {
  }

  configureOAuth(){
    this.oauthPlusService.configure();
  }

  initialRouterCheck() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        let currentUrl: string = event.url;
        this.showAppSections(currentUrl);
        this.checkPageType(currentUrl);
      }
    });
  }

  showAppSections(currentUrl : string){
    const disSecStr = currentUrl.split('/')[1];
    if(this.pathWithoutHeaderFooter.includes(disSecStr) ){
      // this.showHeader = false;
      // this.showFooter = false;
      this.showAsPlugin = true;
    this.renderer.addClass(document.body, 'headless');
    }else{
      // this.showHeader = true;
      // this.showFooter = true;
      this.showAsPlugin = false;
    this.renderer.removeClass(document.body, 'headless');
    }
  }

  // showAppSectionsByQuery(currentUrl : string){
  //   const disSecStr = currentUrl.split('?')[1];
  //   if (disSecStr) {
  //     let disSec = new URLSearchParams(disSecStr);
  //     const disableSections = disSec.get("disableSections")?.split(",") ?? [];
  //    // this.disableSections = disableSections;
  //     if(disableSections.includes('header')) this.showHeader = false;
  //     if(disableSections.includes('footer')) this.showFooter = false;
  //     if(disableSections.includes('showTimer')) this.showTimer = false;
  //   }
  // }



  
  /**
   * checkPageType
   * checking page type whether it falls under kcg auth page, otp auth page or non auth page
   */
  checkPageType(currentUrl: string){
    // if (this.pathWithoutAuth.includes(currentUrl)) {
    //   this.showContainer = true;
    //   if (loggedInData) {
    //     this.oauthPlusService.configure();
    //     setTimeout(()=>{
    //       this.authenticationService.logOut();
    //     },200)
    //   }
    // } else {
    this.kcgAuthInit();
    this.timeoutService.timeoutInit({ init: true });//timeout for kcg auth
    // }
  }

  kcgAuthInit() {
    this.updateOverlay();
    this.getLoggedStatus();
    this.getNotifications();
  }


  getNotifications() {
    const notification = this.notificationService.notificationFlag$.subscribe(data => {
      this.notification = data;
    })
    this.subscriptions.push(notification);
  }

  /**
   * getLoggedStatus
   * 
   */
  public getLoggedStatus() {
    const isValid = this.oauthService.hasValidIdToken() && this.oauthService.hasValidAccessToken();
    this.sharedService.newUserLoggedModel$.subscribe((userlogged) => {
      // this.isLoggedIn = userlogged ? true : false;
      this.showContainer = userlogged ? true : false;
    });
    //this.isLoggedIn = isValid;
    this.showContainer = isValid;
  }

  /**
   * updateOverlay
   * 
   */
  updateOverlay() {
    const overlay = this.sharedService.newFlag$.subscribe(
      (flag: any) => {
        setTimeout(() => {
          this.isLoaderOn = flag;
        }, 0)
        //this.changeDetectorRef.detectChanges();
      });
    this.subscriptions.push(overlay);
  }

  ngOnDestroy() {
    this.subscriptions.map(subscription => subscription.unsubscribe());
  }
}
