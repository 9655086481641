import { Component, OnInit, Renderer2, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UserIdleService } from 'angular-user-idle';
import { Subject, Subscription } from 'rxjs';
import { appConfig } from 'src/app/config/app.config';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { KButtonType, KKcgButtonSubType } from 'ng-k-button';
import { TimeOut, TimeoutService } from '../timeout.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-timeout',
  templateUrl: './timeout.component.html',
  styleUrls: ['./timeout.component.scss']
})
export class TimeoutComponent implements OnInit {
  title = 'SmartClaim';
  public KButtonType = KButtonType;
  public KKcgButtonSubType = KKcgButtonSubType;

  private timeOutModalReference!: NgbModalRef;
  public timeOutTimeLeft: any;
  public timeOutInterval: any;
  @ViewChild('timeoutTemplate', { static: true }) private timeoutTemplate!: TemplateRef<any>;

  private isTimeOutModalOpen = false;

  public subscriptions: Subscription[] = [];


  componentDestroyed$: Subject<boolean> = new Subject()

  constructor(
    private authenticationService: AuthenticationService,
    private userIdle: UserIdleService,
    private modalService: NgbModal,
    private timeoutService: TimeoutService,
    private renderer: Renderer2
  ) { }

  ngOnInit() {
    //this.userIdleCheck();
    this.initTimeout();
  }

  initTimeout() {
    //console.log('show snack');
    this.timeoutService.timeoutSubject$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((res: TimeOut) => {
        //console.log('res', res);
        if (res.init == true) {
          this.userIdleCheck();
        }
      })
  }


  public userIdleCheck() {
    // Start watching for user inactivity.
    this.userIdle.startWatching();
    // Start watching when user idle is starting.
    const start = this.userIdle.onTimerStart().subscribe(count => {

      // if (count === 1) {
      if (!this.isTimeOutModalOpen) {
        this.openTimeoutModal(this.timeoutTemplate);
        this.timeOutTimeLeft = appConfig.userIdleTimeOut;
        this.timeOutInterval = setInterval(() => {
          if (this.timeOutTimeLeft > 0) {
            this.timeOutTimeLeft--;
          } else {
            // console.log('Time is up!');
            this.closeTimeoutModal();
            this.authenticationService.logOut();
          }
        }, 1000);
      }
    });
    this.subscriptions.push(start);
    // Start watch when time is up.
    const timeout = this.userIdle.onTimeout().subscribe((val) => {
      //console.log(val);
    });
    this.subscriptions.push(timeout);
  }

  public openTimeoutModal(timeoutTemplate: any) {
    if (this.timeOutModalReference) { this.timeOutModalReference.close(); }

    this.isTimeOutModalOpen = true;
    this.timeOutModalReference = this.modalService.open(timeoutTemplate, { centered: true, size: 'lg', backdrop: 'static' });
    this.timeOutModalReference.result.then(() => {
      this.closeTimeoutModal();
    }, () => {
      this.closeTimeoutModal();
    });
  }

  public closeTimeoutModal() {
    clearInterval(this.timeOutInterval);
    this.userIdle.resetTimer();
    this.timeOutModalReference.close();
    // this.authService.setUserInfo({});
    this.isTimeOutModalOpen = false;
  }

  public logOut() {
    this.authenticationService.logOut();
    // this.authService.clearUserInfo();
    // this.authService.logOut();
  }

  ngOnDestroy() {
    this.subscriptions.map(subscription => subscription.unsubscribe());
  }

}
