import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { authConfig } from './../config/auth.config';
import { UserService } from './user.service';
import { NavigationStart, Router } from '@angular/router';
import { SharedService } from './shared.service';
import { first, take } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OauthPlusService {
  private _routerSub = Subscription.EMPTY;

  //public subscriptions: Subscription[] = [];
  currentUrlPath: string = '';

  constructor(
    private oauthService: OAuthService,
    private userService: UserService,
    private router: Router,
    private store: SharedService
  ) {
  }



  public async configure() {
    await this.getCurrentPath();
    let currentUrlPath = this.currentUrlPath;
    
    this.oauthService.configure(authConfig);
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    //this.oauthService.setupAutomaticSilentRefresh();

    // this.oauthService.loadDiscoveryDocumentAndLogin()
    this.oauthService.loadDiscoveryDocumentAndTryLogin({
      onTokenReceived: (context) => {
        //Redirect to specific page
        // console.log('contet', context);
        if (context.state) {
          this.router.navigate([context.state]);
          return;
        }
      }
    })
      .then(hasReceivedTokens => {
        this.store.onUserLogged(true);
        // this.router.initialNavigation();
        this.oauthService.setupAutomaticSilentRefresh();
        if (!this.oauthService.hasValidAccessToken() && !hasReceivedTokens) {

          this.oauthService.initLoginFlow(currentUrlPath);
        }
      });

    // this.oauthService.loadDiscoveryDocumentAndTryLogin()
    //   .then(hasReceivedTokens => {
    //     // console.log('---load------');
    //     this.store.onUserLogged(true);
    //     this.addKcgUser();
    //     if (!this.oauthService.hasValidAccessToken() && !hasReceivedTokens) {
    //       this.oauthService.initLoginFlow();
    //     }
    //     this.router.initialNavigation();
    //   })

    this.oauthService.events.subscribe(({ type }: any) => {
      switch (type) {
        case 'token_received':
          // console.log('-----token recieved-----');
          // const addUser = this.userService.addKcgUser().subscribe((res: any) => {
          //console.log('res', res);
          this.store.dispatchEvent('token:refreshed');
          //});
          break;
        // case 'silently_refreshed':
        //   console.log('Token refreshed');
        //   break;
      }
    });

  }

  getCurrentPath() {
    this._routerSub = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.currentUrlPath = event.url;
      }
    })
  }


  addKcgUser() {
    this.userService.addKcgUser().pipe(take(1)).subscribe();
  }

  ngOnDestroy() {
    this._routerSub.unsubscribe();
  }
}