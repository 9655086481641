import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { AuthGuard } from './_services/auth-guard.service';
import { Role } from './_models/role';
import { AccessModules, AccessModuleComponents } from './_models/access-privilege.model';

const routes: Routes = [
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard]
  },
  // {
  //   path: 'claims',
  //   loadChildren: () => import('./pages/claims/claims.module').then(mod => mod.ClaimsModule),
  //   canActivate: [AuthGuard],
  //   data: {moduleKey: AccessModules.claim, moduleComponent: AccessModuleComponents.read}
  // },
  // {
  //   path: 'summaryView/:claimId',
  //   loadChildren: () => import('./pages/summary-view/summary-view.module').then(mod => mod.SummaryViewModule),
  //   canActivate: [AuthGuard],
  //   data: {moduleKey: AccessModules.claimSummary, moduleComponent: AccessModuleComponents.read }
  // },
  // {
  //   path: 'detailView/:claimId',
  //   loadChildren: () => import('./pages/detail-view/detail-view.module').then(mod => mod.DetailViewModule),
  //   canActivate: [AuthGuard],
  //   data: {moduleKey: AccessModules.claimDetail, moduleComponent: AccessModuleComponents.read}
  // },
  {
    path: 'claim/:claimId',
    loadChildren: () => import('./pages/claim/claim.module').then(mod => mod.ClaimModule),
    canActivate: [AuthGuard],
    data: {moduleKey: AccessModules.claim, moduleComponent: AccessModuleComponents.read}
  },
  {
    path: 'uniclaim/:claimId',
    loadChildren: () => import('./pages/uniclaim/uniclaim.module').then(mod => mod.UniclaimModule),
    canActivate: [AuthGuard],
    data: {moduleKey: AccessModules.claim, moduleComponent: AccessModuleComponents.read}
  },
  {
    path: 'settings',
    loadChildren: () => import('./pages/settings/settings.module').then(mod => mod.SettingsModule),
    canActivate: [AuthGuard]
  },
  // {
  //   path: 'login',
  //   loadChildren: () => import('./pages/login/login.module').then(mod => mod.LoginModule)
  // },
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: 'dashboard'
  }
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      initialNavigation: 'enabledNonBlocking'
      // initialNavigation : 'disabled',
      // onSameUrlNavigation: 'reload',
      // scrollPositionRestoration: 'enabled'
    }),
  ],
  exports: [
    RouterModule
  ],
})
export class AppRoutingModule { }
