import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Globals } from '../_globals/globals';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  private newRoute = new Subject<string>();
  newRoute$ = this.newRoute.asObservable();

  public refreshPageCount = 0;

  public currentPageDetails = new Subject<any>();
  currentPageDetails$ = this.currentPageDetails.asObservable();

  private newUserModel = new Subject<string>();
  newUserModel$ = this.newUserModel.asObservable();

  public docCount = 2;

  private localStorageSubject = new Subject<string>();

  private newUserLoggedModel = new Subject<string>();
  newUserLoggedModel$ = this.newUserLoggedModel.asObservable();

  private newFlag = new Subject<string>();
  newFlag$ = this.newFlag.asObservable();

  private loaderReqs: any = [];
  private evnSubj = new Subject<any>();

  constructor(
    // tslint:disable-next-line: variable-name
    public _g: Globals,
  ) { }

  onRouteChage(route: any) {
    this.newRoute.next(route);
    const data = {
      refreshPageCount: this.refreshPageCount++
    };
    this.currentPageDetails.next(data);
  }

  dispatchEvent(dat: string) {
    this.evnSubj.next({ data: dat });
  }
  listenEvent(): Observable<any> {
    return this.evnSubj.asObservable();
  }

  public isInArray(array: any, word: any): boolean {
    return array.indexOf(word.toLowerCase()) > -1;
  }

  public isInString(text: any, word: any): boolean {
    return text.toLowerCase().indexOf(word.toLowerCase()) > -1;
  }

  // // check permission
  // public isEnabled(el: any): boolean {
  //   // Team mgmnt restricted for team members
  //   if (el === 'team' && localStorage.getItem('roleName') === 'Team Member') {
  //     return false;
  //   }

  //   el = (this._g[el]) ? (el) : (el.split('_')[0]); // if sub not found chk for parent
  //   const isEnbld: boolean = (this._g[el]) ? (this._g[el][localStorage.getItem('groupName')]) : (false);
  //   // console.log(el, " : permission => ", isEnbld)
  //   return isEnbld;
  // }

  onLoaderOn(flag: any) {
    // tslint:disable-next-line: no-unused-expression
    (!flag) ? (this.loaderReqs = []) : null;
    this.newFlag.next(flag);
  }

  public showLoader(who: any, isFlag: any) {
    (isFlag) ? this.loaderReqs.push(who) : this.loaderReqs.splice(this.loaderReqs.indexOf(who));
    (this.loaderReqs.length > 0) ? this.onLoaderOn(true) : this.onLoaderOn(false);
  }

  changeUserDetails(usrModel: any) {
    this.newUserModel.next(usrModel);
  }

  changeLocalStorageSubject(state: any) {
    this.localStorageSubject.next(state);
  }

  getLocalStorageSubject() {
    return this.localStorageSubject.asObservable();
  }

  onUserLogged(logModel: any) {
    this.newUserLoggedModel.next(logModel);
  }

}
