import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Globals } from '../_globals/globals';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class ClaimsService {

  constructor(
    private apiService: ApiService,
    private _g: Globals
  ) { }

  public getClaims(requestParam: {}): Observable<any> {
    return this.apiService.getService('getClaims', requestParam);
  }

  public createNewClaim(requestParams: any): Observable<any> {
    return this.apiService.postService(this._g.api.createNewClaim, requestParams);
  }

  public updateClaimcard(requestParams: any): Observable<any> {
    return this.apiService.putService('updateClaimcard', requestParams);
  }

  public getClaimDetailedDataExport(requestParams: any): Observable<any> {
    return this.apiService.getService('getClaimDetailedDataExport', requestParams);
  }

  public trackDocStatus(requestParams: any): Observable<any> {
    return this.apiService.postService('trackDocStatus', requestParams);
  }

  public deleteClaim(requestParams: any): Observable<any> {
    return this.apiService.deleteService('deleteClaim', requestParams);
  }

  public assignLiability(requestParams: {[key: string]: string}): Observable<any>{
    return this.apiService.postService('assignLiability', requestParams )
  }

  public getActiveLiabilityUser(requestParams: {[key: string]: string}): Observable<any>{
    return this.apiService.getService('getActiveLiabilityUser', requestParams )
  }

  public sendClaimFacts(requestParams: {[key: string]: string}): Observable<any>{
    return this.apiService.postService('sendClaimFacts', requestParams )
  }

  public getFormData(requestParams: {[key: string]: string}): Observable<any>{
    return this.apiService.getService('getFormData', requestParams)
  }
  public getAllOrgNames(requestParams:any):Observable<any>{
    return this.apiService.getService('getAllOrgNames', requestParams);
  }
  public getAllOrganizations(requestParams:any):Observable<any>{
    return this.apiService.getService('getAllOrganizations', requestParams);
  }

  public getAllLiabilityOutcomes(requestParams:any):Observable<any>{
    return this.apiService.getService('getAllLiabilityOutcomes', requestParams);
  }
  
}
