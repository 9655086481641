import { Component, OnInit, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { AlertService, TsnackProp, Tsnack } from '../alert.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {

  @ViewChild('snackbar', { static: true }) snackbar!: ElementRef;
  snackContent: any;
  defTimeout = 3000;

  componentDestroyed$: Subject<boolean> = new Subject()


  constructor(
    private alertService: AlertService,
    private renderer: Renderer2,
    private el: ElementRef
  ) { }

  ngOnInit(): void {
    this.showSnack();
    //this.appendSnack();


  }

  showSnack() {
    //console.log('show snack');
    this.alertService.alertSubject$
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe((res: TsnackProp) => {
      //console.log('res', res);
      if (res.show == true) {
        const snackData = res.data;
        const snackContent = this.renderer.createElement('div')
        this.appendSnack(snackData, snackContent);
        const timeOut = snackData.time ? snackData.time : this.defTimeout;
        setTimeout(() => {
          this.removeSnack(snackContent);
        }, timeOut);
      }
    })
  }

  createSnack(params: Tsnack, snackContent: any) {
    //this.renderer.setProperty(this.snackContent, 'id', 'snack-content');
    this.renderer.addClass(snackContent, 'snackbar');
    switch(params.status){
      case 'success':
        this.renderer.addClass(snackContent,'w_alert_success');
      break;
      case 'error':
        this.renderer.addClass(snackContent,'w_alert_danger');
      break;

    }
    const text = this.renderer.createText(params.message);
    this.renderer.appendChild(snackContent, text);
    
    const closeSpan = this.renderer.createElement("button");
    this.renderer.appendChild(snackContent,closeSpan);
    this.renderer.addClass(closeSpan,'btn-close');
    this.renderer.addClass(closeSpan,'snack-close');
    //this.renderer.appendChild(closeSpan, this.renderer.createText("[X]"))
    this.renderer.listen(closeSpan, 'click', (evt) => {
      this.removeSnack(snackContent);
    })
  }

  appendSnack(params: Tsnack, snackContent: any) {
    this.createSnack(params, snackContent)
    this.renderer.appendChild(this.snackbar.nativeElement, snackContent);
  }

  removeSnack(snackContent: any) {
    //this.renderer.addClass(this.snackContent, 'hide');
    // setTimeout(() => {
    this.renderer.removeChild(this.snackbar.nativeElement, snackContent)
    // }, 2000)
  }


  ngOnDestroy() {
    this.componentDestroyed$.next(true)
    this.componentDestroyed$.complete()
  }

}
