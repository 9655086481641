import { Injectable } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})

export class CustomDateFormatService extends NgbDateParserFormatter {

  public placeholder = '';
  public dateFormat = '';

  constructor() {
    super();
    this.placeholder = this.getDatePattern((new Intl.DateTimeFormat()).resolvedOptions().locale);

    const formatter = new Intl.DateTimeFormat((new Intl.DateTimeFormat()).resolvedOptions().locale).formatToParts();

    formatter.map(e => {
      if (e.type == 'month') this.dateFormat += 'MM';
      else if (e.type == 'day') this.dateFormat += 'dd';
      else if (e.type == 'year') this.dateFormat += 'YYYY';
      else this.dateFormat += '/';
    })
  }

  getDatePattern(locale: any) {
    var formatter = new Intl.DateTimeFormat(locale).formatToParts();

    return formatter.map(function (e) {
      switch (e.type) {
        case 'month':
          return 'MM';
        case 'day':
          return 'DD';
        case 'year':
          return 'YYYY';
        default:
          return e.value;
      };
    }).join('');
  }

  parse(value: string): any {
    if (value) {
      value = value.trim();
      let mdt = moment(value, this.placeholder)
    }
    return null;
  }

  format(date: NgbDateStruct): string {
    if (!date) return '';
    let mdt = moment([date.year, date.month - 1, date.day]);
    if (!mdt.isValid()) return '';
    return mdt.format(this.placeholder);
  }
}
