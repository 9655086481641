import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class PasswordService {

    private passwordSubject = new BehaviorSubject({ show: false });
    public passwordSubject$ = this.passwordSubject.asObservable();

    constructor() { }

    showChangePassword() {
        this.passwordSubject.next({ show: true });
    }
}
