import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReassignService {

  constructor() { }

  public assignUser = new BehaviorSubject<boolean>(false);

  setAssignUser(changeToggle: boolean) {
    this.assignUser.next(changeToggle);
  }

  getAssignUser() {
    return this.assignUser.asObservable();
  }
}
