import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
//import { KSideBarModule } from 'ng-k-side-bar';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { KButtonModule } from 'ng-k-button';
import { KIconModule } from 'ng-k-icon';
// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatInputModule } from '@angular/material/input';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { CustomModule } from 'src/app/_custom/custom.module';

const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/yyyy',
  },
  display: {
    dateInput: 'DD/MM/yyyy',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@NgModule({
  declarations: [
    DashboardComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    //KSideBarModule,
    InfiniteScrollModule,
    // NgbModule,
    KIconModule,
    KButtonModule,
    MatDatepickerModule,
    MatInputModule,
    MatMomentDateModule,
    CustomModule
  ],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class DashboardModule { }
