// token.interceptor.ts
import { Injectable, Injector } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
  HttpHandler,
  HttpEvent,
  HttpParams,
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
// import 'rxjs/add/operator/catch';
import { throwError } from 'rxjs';

import { authModuleConfig } from './../config/authmodule.config';
import { OAuthService } from 'angular-oauth2-oidc';
import { NotificationService } from '../_services/notification.service';
import { SharedService } from '../_services/shared.service';
import { AuthenticationService } from '../_services/authentication.service';
import { AlertService } from '../_shared/alert/alert.service';
// import { SwalService } from '../_services/swal.service';
import { Router } from '@angular/router';

@Injectable()

export class TokenInterceptor implements HttpInterceptor {

  constructor(
    private injector: Injector,
    private sharedService: SharedService,
    private oauthService: OAuthService,
    // private swalService: SwalService,
    private authService: AuthenticationService,
    public notificationService: NotificationService,
    public alertService : AlertService,
    private router: Router
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const authService = this.injector.get(AuthenticationService);

    let customReq: HttpRequest<any>;

    let params = new HttpParams();
    params = params.append('noCache', String(new Date().getTime()));

    const token = this.oauthService.getAccessToken();

    const sendAccessToken = authModuleConfig.resourceServer.sendAccessToken;

    if (token) {
      let headerReq = request.headers//.set('token', token);
      if (sendAccessToken) {
        const authorizationToken = 'Bearer ' + token;
        headerReq = headerReq.set('Authorization', authorizationToken);
      }
      customReq = request.clone({
        headers: headerReq,
        params
      });
    } else {
      customReq = request;
    }

    return next
      .handle(customReq)
      .pipe(tap((ev: HttpEvent<any>) => {
        if (ev instanceof HttpResponse) {
          // console.log('processing response', ev);
        }
      }),
        catchError(err => {
          if (err instanceof HttpErrorResponse) {
            //this.sharedService.onLoaderOn(false);
            // console.log('Processing http error====', err);
            // if (err.error.message) {
            //   const tokenError = ['Token expired.', 'Not a valid token.', 'Invalid or expired token.', 'Not a valid user.'];
            //   if (tokenError.includes(err.error.message)) {
            //     // this.swalService.toast(err.error.message, 'error');
            //     // this.authService.clearUserInfo();
            //     this.authService.logOut();
            //   } else {
            //    // console.log(err.error.message);
            //     // this.swalService.toast(err.error.message, 'error');
            //     //this.notificationService.showMessage(true, 'error', err.error.message);
            //   }
            // } else {
            //  // console.log('An error occurred while connecting to the server.', 'error');
            //   // this.swalService.toast('An error occurred while connecting to the server.', 'error');
            // }
            if(err.error.code === 401){
                this.alertService.showSnackBar({
                    status: 'error',
                    message: "Token Expired."
                })
                setTimeout(()=>{
                    this.authService.logOut();
                    this.router.navigate(['']);
                }, 1000 );
            }
          }

          // return throwError(new Error(err));
          return throwError(err);
        }));
  }
}
