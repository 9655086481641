<div [hidden]="!isLoaderOn" class="page-overlay">
  <div class="page-loader">
    <div class="loader ">Loading...</div>
    <div class="page-loader-title">Please wait</div>
  </div>
</div>

<div *ngIf="showContainer" class="main-container">
  <k-simple-alert style="position: absolute; z-index: 100000;" [display]="notification['displayMessage']"
    [msgType]="notification['msgType']" [message]="notification['message']" [timeout]="notification['timeout']">
  </k-simple-alert>

  <app-header *ngIf="!showAsPlugin"></app-header>
  <div class="containerSpace">
    <div class="main">
      <div class="section">
        <button kClickEvent sidebarId="testId" id="toggleBtn" hidden></button>
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
  <app-alert></app-alert>
  <app-timeout *ngIf="!showAsPlugin"></app-timeout>
  <app-password-update  *ngIf="!showAsPlugin"></app-password-update>
  <app-footer *ngIf="!showAsPlugin"></app-footer>
</div>

