import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { environment } from 'src/environments/environment';

/**
 * 
 */
@Injectable({
    providedIn: 'root'
})
export class KcgService {

    // apiUrl: 'https://kcg-arm-api.kcadev.com/',
    // version: 'v1/',
    // api: {
    //   'basicUserAuthorization': 'Rights/basicUserAuthorization/'
    // }
    constructor(
        public apiService: ApiService
    ) { }


    /**
    * basicUserAuthorization
    *
    * @param object requestParam
    *
    * @return object
    */
    public basicUserAuthorization(): Observable<any> {
        const endPoint = 'Rights/basicUserAuthorization/';
        const kcgApi = environment.KcgApiUrl + endPoint;
        return this.apiService.getService('', {}, kcgApi);
    }

  /**
   * isPasswordExpired
   *
   * @param object requestParam
   *
   * @return object
   */
    public isPasswordExpired(): Observable<any> {
        const endPoint = 'User/passwordIsExpired/';
        const kcgApi = environment.KcgApiUrl + endPoint;
        return this.apiService.getService('', {}, kcgApi);
    }

    
  /**
   * changePassword
   *
   * @param object requestParam
   *
   * @return object
   */
     public changePassword(requestParam: {}): Observable<any> {
        const endPoint = 'User/passwordChange/';
        const kcgApi = environment.KcgApiUrl + endPoint;
        return this.apiService.putService( '', {}, kcgApi,requestParam);
    }
}
