import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatInputModule } from '@angular/material/input';

import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { OAuthModule, ValidationHandler } from 'angular-oauth2-oidc';
import { UserIdleModule } from 'angular-user-idle';

import { KButtonModule } from 'ng-k-button';
import { KNotificationsModule } from 'ng-k-notifications';

import { AppRoutingModule } from './app-routing.module';
import { authModuleConfig } from './config/authmodule.config';
import { AppComponent } from './app.component';
import { Globals } from './_globals/globals';
import { appConfig } from './config/app.config';
import { TokenInterceptor } from './_interceptors/token.interceptor';
import { AlertModule } from './_shared/alert/alert.module';
import { LayoutModule } from './_shared/layout/layout.module';
import { TimeoutModule } from './_shared/timeout/timeout.module';

import { DashboardModule } from './pages/dashboard/dashboard.module';
import { PasswordModule } from './_shared/password/password.module';
import { ReassignService } from './_services/reassign.service';
import { BrbUseCaseComponent } from './pages/settings/brb-use-case/brb-use-case.component';
//import { AppInitService } from './_services/app-init.service';

// export function initializeApp(appInitService: AppInitService) {
//     return () =>  {
//       try{
//         //return appInitService.init().toPromise();
//         return {};
//       }catch(err){
//         return {}
//       }
//     }
// }


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule,
    AppRoutingModule,
    
    FormsModule,
    ReactiveFormsModule,

    NgbModule,
    OAuthModule.forRoot(authModuleConfig),
    UserIdleModule.forRoot({ idle: appConfig.userIdle, timeout: appConfig.userIdleTimeOut, ping: appConfig.userIdlePing }),
    
    KButtonModule,
    KNotificationsModule,
    MatDatepickerModule,
    MatInputModule,
    MatMomentDateModule,
    
    AlertModule,
    LayoutModule,
    TimeoutModule,
    DashboardModule,
    PasswordModule
  ],
  providers: [
    Globals,
    HttpClientModule, 
    // AppInitService,
    //  { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [AppInitService], multi: true },
    // { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: ValidationHandler, useClass: JwksValidationHandler },
    ReassignService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
