import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

export type Tsnack = {
  message: string,
  time?: number,
  status?: string
}

export type TsnackProp = {
  show: boolean,
  data: Tsnack
}

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  private alertSubject = new BehaviorSubject<TsnackProp>({show: false, data: {message: '', time: 0}});
  public alertSubject$ = this.alertSubject.asObservable();

  constructor() { }

  showSnackBar(params: Tsnack){
    //console.log('snacks',params);
    this.alertSubject.next({show: true,data: params});
  }
}
