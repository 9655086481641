export class Globals {
  api = {

    // User
    addKcgUser: 'addKcgUser',

    // Permission
    getRightsByToken: 'getRightsByToken',
    getAllRightsByToken: 'getAllRightsByToken',
    updateUserToken: 'updateUserToken',
    updateOrgColorConfig: 'updateOrgColorConfig',
    getOrgColorConfig: 'getOrgColorConfig',

    // claim/manage
    getWizardQuestions: 'getWizardQuestions',
    saveWizardQuestions: 'saveWizardQuestions',
    getClaimData: 'getClaimData',
    getClaimInfo: 'getClaimInfo',
    getClaimFeedback: 'getClaimFeedback',
    updateClaimFeedback: 'updateClaimFeedback',
    getClaimDocs: 'getClaimDocs',
    getClaimDocUrl: 'getClaimDocUrl',

    // New claim
    createNewClaim: 'createNewClaim',

    // Public API
    getAllPublicAPI: 'getAllPublicAPI',
    createNewPubApi: 'createNewPubApi',
    getPublicApiById: 'getPublicApiById',
    editPubApiById: 'editPubApiById',

    // org form fields
    getFormFields: 'getClaimFormFields',
    saveFormFields: 'updateClaimFormFields',

    // org email
    getOrgEmail: 'getOrgEmail',
    saveOrgEmail: 'addOrgEmail',

    // org email
    getUseCaseInformation: 'getUseCaseInformation',
    updateUseCaseInformation: 'updateUseCaseInformation',

    //org list
    getAllOrg : 'getAllOrg',
    getAllOrgNames: 'getAllOrgNames',
    getAllOrganizations:'getAllOrganizations',

    // use case list
    getAllUseCases: 'getAllUseCases',
    updateOrgUsecases: 'updateOrgUsecases',

    //brb
    getAllUseCase: 'getAllUseCase',
    getUseCaseById: 'getUseCaseById',
    deleteUseCase: 'deleteUseCase',
    createNewUseCase: 'createNewUseCase',
    updateUseCase: 'updateUseCase'
  };

}
