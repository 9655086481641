import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Role } from '../_models/role';
import { AuthenticationService } from '../_services/authentication.service';

type RoleKeys = keyof Role;
type RoleValues =  Role[RoleKeys];

@Directive({
  selector: '[appRoleAccess]'
})
export class RoleAccessDirective {

  userRoles!: Array<RoleValues>;

  constructor(
    private authService: AuthenticationService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) { }

  @Input()
  set appRoleAccess(roles: Array<RoleValues>) {
    if (!roles || !roles.length) {
      throw new Error('Roles value is empty or missed');
    }
    this.userRoles = roles;
  }

  ngOnInit() {
    let hasAccess = false;
    const currentUserRole = this.authService.getUserRole();
    if (this.userRoles?.length) {
      hasAccess = this.userRoles.some(r =>  r === currentUserRole);
    }
    if (hasAccess) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }




}
